import { Three } from '../../Deps/Three.ts';
import { matmul } from '../../Helpers/Math.ts';
import { Time } from '../../Helpers/Time.ts';
import { Vector3 } from '../../Helpers/Vector3.ts';
import { IThreeJSObject3D } from '../API.ts';
import { Camera } from './Camera.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';

type HypercubeRendererProps = {
	color: number;
	wireframe: boolean;
};

const frontZ = [0, 1, 2, 3];
const backZ = [4, 7, 6, 5];
const frontY = [0, 4, 5, 1];
const frontX = [1, 5, 6, 2];
const backY = [2, 6, 7, 3];
const backX = [3, 7, 4, 0];

const hyper = (indices: number[]) => indices.map(i => i + 8);

export class HypercubeRenderer extends ThreeJSComponent<HypercubeRendererProps>
	implements IThreeJSObject3D {
	_threeObject: Three.Object3D | null = null;
	_geometry = new Three.BufferGeometry();

	createThreeObject = (): Promise<Three.Object3D> => {
		// Front, back, thenwrap around top, right, bottom, left
		const triangles = [
			...cube(frontZ, backZ),
			...cube(hyper(frontZ), hyper(backZ)),
			...hypercube(frontX, hyper(frontX)),
			...hypercube(frontY, hyper(frontY)),
			...hypercube(frontZ, hyper(frontZ)),
			...hypercube(backZ, hyper(backZ)),
			...hypercube(backY, hyper(backY)),
			...hypercube(backX, hyper(backX)),
		];

		this._geometry.setIndex(triangles);
		this._geometry.setAttribute(
			'position',
			new Three.Float32BufferAttribute(this.getVertices(1, 0), 3),
		);

		// random hex color
		const material = new Three.MeshBasicMaterial({
			color: this.data?.color ?? this.getRandomColor(),
			opacity: 0.5,
			transparent: true,
			wireframe: this.data?.wireframe,
		});
		const mesh = new Three.Mesh(this._geometry, material);
		mesh.name = 'HypercubeRenderer';
		return Promise.resolve(mesh);
	};

	getVertices = (t: number, d: number): number[] => {
		const l = 1; // length of side
		const p = l / 2; // half length of side / position
		// const d = 1; // distance from center
		let theta = t; // angle of rotation

		const vertices4D = [
			// Start CubeRenderer
			[-p, p, p, -p],
			[p, p, p, -p],
			[p, -p, p, -p],
			[-p, -p, p, -p],
			[-p, p, -p, -p],
			[p, p, -p, -p],
			[p, -p, -p, -p],
			[-p, -p, -p, -p],

			// End CubeRenderer
			[-p, p, p, p],
			[p, p, p, p],
			[p, -p, p, p],
			[-p, -p, p, p],
			[-p, p, -p, p],
			[p, p, -p, p],
			[p, -p, -p, p],
			[-p, -p, -p, p],
		];

		const rotateZW = [
			[1, 0, 0, 0],
			[0, 1, 0, 0],
			[0, 0, Math.cos(theta), -Math.sin(theta)],
			[0, 0, Math.sin(theta), Math.cos(theta)],
		];

		const rotateXY = [
			[Math.cos(theta), -Math.sin(theta), 0, 0],
			[Math.sin(theta), Math.cos(theta), 0, 0],
			[0, 0, 1, 0],
			[0, 0, 0, 1],
		];

		const vertices3D = vertices4D.flatMap((v) => {
			const rotated = matmul(rotateXY, matmul(rotateZW, v))
			const w = 1 - (rotated[3] / d);
			const vec3 = [
				rotated[0] * w,
				rotated[1] * w,
				rotated[2] * w,
			];

			return vec3;
		});

		return vertices3D
	};

	getRandomColor = (): number => {
		return 0xffffff * Math.random() * 2;
	};

	getDefaultData = (): HypercubeRendererProps => {
		return {
			color: this.getRandomColor(),
			wireframe: true,
		};
	};

	update = () => {
		this.super.update();

		const d =
			Vector3.distance(
				this.entity!.transform!.position,
				Camera.main!.transform.position,
			);

		this._geometry.setAttribute(
			'position',
			new Three.Float32BufferAttribute(
				this.getVertices(Time.curTime, d),
				3,
			),
		);
	};
}

// 0,1,2,3
// 4,7,6,5

// 0,4,5,1
// 1,5,6,2
// 2,6,7,3
// 3,7,4,0
const cube = (front: number[], back: number[]) => {
	const top = [front[0], back[0], back[3], front[1]];
	const right = [front[1], back[3], back[2], front[2]];
	const bottom = [front[2], back[2], back[1], front[3]];
	const left = [front[3], back[1], back[0], front[0]];
	return [front, back, top, left, right, bottom].flatMap(face)
}

// 0,1,2,3
// 8,9,10,11

// 0,1,9,8
// f[0], f[1], b[1], b[0]
const hypercube = (front: number[], back: number[]) => {
	const top = [front[0], front[1], back[1], back[0]];
	const right = [front[1], back[1], back[2], front[3]];
	const bottom = [front[2], front[3], back[2], back[3]];
	const left = [front[0], back[0], back[3], front[2]];
	return [front, back, top, left, right, bottom].flatMap(face)
}


const face = (faceVerts: number[]): number[] => {
	return [
		faceVerts[0],
		faceVerts[1],
		faceVerts[2],
		faceVerts[0],
		faceVerts[2],
		faceVerts[3],
	];
};

// 0 = (-p, p, p)
// 1 = (p, p, p)
// 2 = (p, -p, p)
// 3 = (-p, -p, p)

// 4 = (-p, p, -p)
// 5 = (p, p, -p)
// 6 = (p, -p, -p)
// 7 = (-p, -p, -p)

// const vertices3D = [
//     -p, p, p,
//     p, p, p,
//     p, -p, p,
//     -p, -p, p,
//     -p, p, -p,
//     p, p, -p,
//     p, -p, -p,
//     -p, -p, -p,
// ];
