import { Entity } from "../../Ontology/Entity.ts";
import { ThreeJSTransform } from "../Components/ThreeJSTransform.ts";

export class SimpleEntity extends Entity {
  getDefaultBlueprint = () => {
    return {
      name: "simple",
      components: [
        {
          component: new ThreeJSTransform(),
          data: {
            position: {
              x: 1,
              y: 2,
              z: 3,
            },
            rotation: {
              w: 0,
              x: 0,
              y: 0,
              z: 0,
            },
          },
        },
      ],
    };
  };
}
