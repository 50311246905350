import { Component } from "../Ontology/Entity/Component.ts";

type Empty = {};

export class GaiaNode extends Component<Empty> {
  privacy: "private" | "public" = "private";

  create = () => {
    this.drawNodes();
  };

  drawNodes = () => {
  };
}

// old

// const input = {
//   "nodes": [
//     {
//       "id": "id1",
//       "name": "name1",
//       "val": 1,
//     },
//     {
//       "id": "id2",
//       "name": "name2",
//       "val": 10,
//     },
//   ],
//   "links": [
//     {
//       "source": "id1",
//       "target": "id2",
//     },
//   ],
// };
// const graph = new ThreeForceGraph();
// graph.graphData(input);
// console.log(graph)
// const scene = new three.Scene();
// scene.add(graph);
// scene.add(new three.AmbientLight(0xbbbbbb));

// // add a red sphere at (0, 0, 0)
// // const geometry = new three.SphereGeometry(0.5, 32, 32);
// // const material = new three.MeshBasicMaterial({ color: 0xff0000 });
// // const sphere = new three.Mesh(geometry, material);
// // scene.add(sphere);

// const camera = new three.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.001, 10000 );
// camera.aspect = window.innerWidth/window.innerHeight;
// camera.updateProjectionMatrix();
// camera.lookAt(graph.position);
// camera.position.z = 100;

// const renderer = new three.WebGLRenderer();
// renderer.setSize( window.innerWidth, window.innerHeight );

// document.body.appendChild( renderer.domElement );

// (function animate() { // IIFE
//   graph.tickFrame();

//   // Frame cycle
//   renderer.render(scene, camera);
//   requestAnimationFrame(animate);
// })();
