import { Three } from '../../Deps/Three.ts';
import { IThreeJSObject3D } from '../API.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';

type EmptyProps = {};

export class Empty extends ThreeJSComponent<EmptyProps> implements IThreeJSObject3D {
	_threeObject: Three.Object3D | null = null;

	createThreeObject = (): Promise<Three.Object3D> => {
		return Promise.resolve(new Three.Group());
	};
}
