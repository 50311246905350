import { Debug } from './Debug.ts';

type Files =
	| 'ontology'
	| 'meta'
	| 'animus'
	| 'axon'
	| 'ontology-axon'
	| 'pool'
	| 'world'
	| '_';

const emoji: Record<Files, string> = {
	_: '💩',
	ontology: '📦',
	meta: '📝',
	animus: '🌿',
	axon: '🧠',
	'ontology-axon': '🧠📦',
	pool: '🏊‍♀️',
	world: '🌎',
};

const silencedLogs = ['axon'];
const silenceQuick = true;

export class Note {
	private static _internal_preamble = (
		file: Files,
		level: 'info' | 'warn' | 'error',
	): string[] => {
		const timestamp = new Date().toLocaleTimeString();
		let suffix: string[] = [];
		if (level === 'warn') {
			suffix = ['color: #ffa500', '⚠️'];
		} else if (level === 'error') {
			suffix = ['color: #ff0000', '🚨'];
		} else {
			suffix = ['|'];
		}
		return [`%c[${timestamp}] ${emoji[file]} ${file}`, ...suffix];
	};

	private static _internal_log(
		method: (...args: any[]) => any,
		...args: any[]
	) {
		method(...args);
	}

	static quick(...data: any[]) {
		if (silenceQuick) {
			return;
		}
		if (!Debug.enabled) return;
		this._internal_log(
			console.groupCollapsed,
			...this._internal_preamble('_', 'info'),
			...data,
		);
		console.trace();
		console.groupEnd();
	}

	static info(file: Files, ...data: any[]) {
		if (silencedLogs.includes(file)) {
			return;
		}
		if (!Debug.enabled) {
			this._internal_log(
				console.log,
				...this._internal_preamble(file, 'info'),
				...data,
			);
			return;
		}
		this._internal_log(
			console.groupCollapsed,
			...this._internal_preamble(file, 'info'),
			...data,
		);
		console.trace();
		console.groupEnd();
	}

	static warn(file: Files, ...data: any[]) {
		if (silencedLogs.includes(file)) {
			return;
		}
		this._internal_log(
			console.groupCollapsed,
			...this._internal_preamble(file, 'warn'),
			...data,
		);
		console.trace();
		console.groupEnd();
	}

	static error(file: Files, ...data: any[]) {
		if (silencedLogs.includes(file)) {
			return;
		}
		this._internal_log(
			console.groupCollapsed,
			...this._internal_preamble(file, 'error'),
			...data,
		);
		console.trace();
		console.groupEnd();
	}

	static trace(file: Files, ...data: any[]) {
		if (silencedLogs.includes(file)) {
			return;
		}
		Note.info(file, ...data);
	}
}

export const qnote = (...data: any[]) => Note.quick(...data);
