import {
  ID_DIVIDER,
  MESSAGE_DIVIDER,
  SIMPLE_INLINE_DIVIDER,
} from "./Constants.ts";
import {
  HostParams_PARTIALLY_ENCODED,
  HostParams,
  DirectedMessage,
  JoinParams_PARTIALLY_ENCODED,
  JoinParams,
  Message,
} from "./Types.ts";

export const getRandomId = () => {
  return Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
};

export const getUrl = (path: string, params?: Record<string, string>) => {
  if (!params) {
    return path;
  }
  return `${path}?${new URLSearchParams(params).toString()}`;
};

export const encodeMessageList = (
  messages: Message[],
): string => {
  return messages
    .map((msg) => `${msg.type}${ID_DIVIDER}${msg.payload}`)
    .join(MESSAGE_DIVIDER);
};

export const encodeDirectedMessageList = (
  messages: DirectedMessage[],
): string => {
  return messages
    .map((msg) =>
      `${msg.type}${ID_DIVIDER}${msg.payload}${ID_DIVIDER}${
        msg.targets?.join(SIMPLE_INLINE_DIVIDER)
      }`
    )
    .join(MESSAGE_DIVIDER);
};

export const encodeAnnouncement = (
  announce: HostParams,
): HostParams_PARTIALLY_ENCODED => {
  const params: HostParams_PARTIALLY_ENCODED = {
    lobbyId: announce.lobbyId,
    peerId: announce.peerId,
    key: announce.key,
  };
  if (announce.port) {
    params.port = announce.port.toString();
  }
  if (announce.numPlayers) {
    params.numPlayers = announce.numPlayers.toString();
  }
  if (announce.maxPlayers) {
    params.maxPlayers = announce.maxPlayers.toString();
  }
  if (announce.publicData) {
    params.publicData = announce.publicData;
  }
  if (announce.secretData) {
    params.secretData = announce.secretData;
  }
  if (announce.approvals) {
    params.approvals = announce.approvals.join(SIMPLE_INLINE_DIVIDER);
  }
  if (announce.rejections) {
    params.rejections = announce.rejections.join(SIMPLE_INLINE_DIVIDER);
  }
  if (announce.readIds) {
    params.readIds = announce.readIds.join(SIMPLE_INLINE_DIVIDER);
  }
  if (announce.messages) {
    params.messages = encodeDirectedMessageList(announce.messages);
  }
  return params;
};

export const encodeJoin = (
  join: JoinParams,
): JoinParams_PARTIALLY_ENCODED => {
  const params: JoinParams_PARTIALLY_ENCODED = {
    lobbyId: join.lobbyId,
    peerId: join.peerId,
  };
  if (join.address) {
    params.address = join.address;
  }
  if (join.port) {
    params.port = join.port.toString();
  }
  if (join.publicData) {
    params.publicData = join.publicData;
  }
  if (join.secretData) {
    params.secretData = join.secretData;
  }
  if (join.readIds) {
    params.readIds = join.readIds.join(SIMPLE_INLINE_DIVIDER);
  }
  if (join.messages) {
    params.messages = encodeMessageList(join.messages);
  }
  return params;
};

// "123:hello bob!,456:how are you?" --> { ["123", "hello bob"], ["456", "how are you"] }
export const decodeMessageList = (
  messages: string,
): Message[] | null => {
  return messages
    ? String(messages).split(MESSAGE_DIVIDER).reduce(
      (acc: Message[], msg: string) => {
        const [type, payload] = msg.split(ID_DIVIDER);
        if (type && payload) {
          acc.push({ type, payload });
        }
        return acc;
      },
      [],
    )
    : null;
};

export const decodeDirectedMessageList = (
  messages: string,
): DirectedMessage[] | null => {
  return messages
    ? String(messages).split(MESSAGE_DIVIDER).reduce(
      (acc: DirectedMessage[], msg: string) => {
        const [type, payload, targets] = msg.split(ID_DIVIDER);
        if (type && payload) {
          acc.push({
            type,
            payload,
            targets: targets ? targets.split(SIMPLE_INLINE_DIVIDER) : undefined,
          });
        }
        return acc;
      },
      [],
    )
    : null;
};
