import { meta } from '../Ontology/Meta.ts';
import { alloc } from './Alloc.ts';
import { Matrix4 } from './Matrix4.ts';
import { Vector3, Vector3Like } from './Vector3.ts';

export type QuaternionLike = { x: number; y: number; z: number; w: number };

export class Quaternion {
	x: number;
	y: number;
	z: number;
	w: number;

	static get identity(): Quaternion {
		return alloc(new Quaternion(0, 0, 0, 1));
	}

	static staticIdentity = Object.freeze(Quaternion.identity);

	constructor(
		x: QuaternionLike | Vector3Like | number | undefined = undefined,
		y = 0,
		z = 0,
		w = 0,
	) {
		if (x != null && typeof x === 'number') {
			this.x = x;
			this.y = y;
			this.z = z;
			this.w = w;
		} else if (x != null && (x as QuaternionLike).w !== undefined) {
			const qq: QuaternionLike = x as QuaternionLike;
			this.x = qq.x;
			this.y = qq.y;
			this.z = qq.z;
			this.w = qq.w;
		} else {
			this.x = 0;
			this.y = 0;
			this.z = 0;
			this.w = 1;
		}
	}

	clone(): Quaternion {
		return alloc(new Quaternion(this));
	}

	static fromQuaternionLike(quat: QuaternionLike): Quaternion {
		return alloc(new Quaternion(quat.x, quat.y, quat.z, quat.w));
	}

	static fromEuler(
		q: QuaternionLike,
		roll: number | Vector3Like, // roll=x
		pitch: number | undefined = undefined, // pitch=y
		yaw: number | undefined = undefined, // yaw=z
	): QuaternionLike {
		const isNumber = typeof roll === 'number';
		const x = isNumber ? roll : roll.x;
		const y = isNumber ? pitch! : roll.y;
		const z = isNumber ? yaw! : roll.z;

		const cy = Math.cos(z * 0.5);
		const sy = Math.sin(z * 0.5);
		const cp = Math.cos(y * 0.5);
		const sp = Math.sin(y * 0.5);
		const cr = Math.cos(x * 0.5);
		const sr = Math.sin(x * 0.5);

		q.x = sr * cp * cy - cr * sp * sy;
		q.y = cr * sp * cy + sr * cp * sy;
		q.z = cr * cp * sy - sr * sp * cy;
		q.w = cr * cp * cy + sr * sp * sy;
		return q
	}

	static fromEuler_ALLOC(
		x: number | Vector3Like,
		y: number | undefined = undefined,
		z: number | undefined = undefined,
	): Quaternion {
		const newQuat = alloc(new Quaternion())
		Quaternion.fromEuler(newQuat, x, y, z)
		return newQuat
	}

	static fromPool(x: number, y: number, z: number, w: number): Quaternion {
		const quat = (meta.getPool(Quaternion)!)
			.take() as unknown as Quaternion;
		quat.x = x;
		quat.y = y;
		quat.z = z;
		quat.w = w;
		return quat;
	}

	identity(): Quaternion {
		return this.set(0, 0, 0, 1);
	}

	// https://en.wikipedia.org/wiki/Conversion_between_quaternions_and_Euler_angles
	fromEuler_ALLOC(
		roll: number | Vector3Like, // roll=x
		pitch: number | undefined = undefined, // pitch=y
		yaw: number | undefined = undefined, // yaw=z
	): Quaternion {
		const q = new Quaternion();
		Quaternion.fromEuler(q, roll, pitch, yaw);
		return q
	}

	fromAxisAngle(axis: Vector3Like, angle: number): Quaternion {
		const halfAngle = angle * 0.5;
		const s = Math.sin(halfAngle);
		return this.set(
			Math.cos(halfAngle),
			s * Math.cos(axis.x),
			s * Math.cos(axis.y),
			s * Math.cos(axis.z),
		);
	}

	euler(): Vector3 {
		return alloc(
			new Vector3(
				Math.atan2(
					2 * (this.x * this.y + this.z * this.w),
					1 - 2 * (this.y * this.y + this.z * this.z),
				),
				Math.atan2(
					2 * (this.y * this.z + this.x * this.w),
					1 - 2 * (this.z * this.z + this.x * this.x),
				),
				Math.asin(2 * (this.x * this.z - this.y * this.w)),
			),
		); //.multiply(180 / Math.PI);
	}

	axis(): Vector3 {
		const halfTheta = Math.acos(this.w);
		const sinHalfTheta = Math.sin(halfTheta);
		return alloc(
			new Vector3(
				this.x / sinHalfTheta,
				this.y / sinHalfTheta,
				this.z / sinHalfTheta,
			),
		);
	}

	angle(): number {
		return Math.acos(this.w) * 2;
	}

	conjugate(): Quaternion {
		return this.set(
			-this.x,
			-this.y,
			-this.z,
			this.w,
		);
	}

	invert(): Quaternion {
		const norm = this.x * this.x + this.y * this.y + this.z * this.z +
			this.w * this.w;
		return this.set(
			-this.x / norm,
			-this.y / norm,
			-this.z / norm,
			this.w / norm,
		);
	}

	normalize(): Quaternion {
		const norm = this.x * this.x + this.y * this.y + this.z * this.z +
			this.w * this.w;
		return this.set(
			this.x / norm,
			this.y / norm,
			this.z / norm,
			this.w / norm,
		);
	}

	__cachedQuaternionForRotate = {
		x: 0,
		y: 0,
		z: 0,
		w: 1,
	};
	rotate(axis: Vector3, angle: number) {
		const otherQ = Quaternion.fromEuler(
			this.__cachedQuaternionForRotate,
			axis.x * angle,
			axis.y * angle,
			axis.z * angle,
		);
		return this.multiply(otherQ);
	}

	set(
		x: QuaternionLike | number | undefined = undefined,
		y = 0,
		z = 0,
		w = 0,
	): Quaternion {
		if (x != null && typeof x === 'number') {
			this.x = x;
			this.y = y;
			this.z = z;
			this.w = w;
		} else if (x != null && (x as QuaternionLike).w != null) {
			const qq: QuaternionLike = x as QuaternionLike;
			this.x = qq.x;
			this.y = qq.y;
			this.z = qq.z;
			this.w = qq.w;
		} else {
			this.x = 0;
			this.y = 0;
			this.z = 0;
			this.w = 1;
		}
		return this;
	}

	rotateX(angle: number) {
		return this.rotate(Vector3.staticRight, angle);
	}

	rotateY(angle: number) {
		return this.rotate(Vector3.staticUp, angle);
	}

	rotateZ(angle: number) {
		return this.rotate(Vector3.staticForward, angle);
	}

	lookRotation(forward: Vector3, up: Vector3) {
		const fwd = forward.normalize();
		const right = up.cross(fwd).normalize();
		const up2 = fwd.clone().cross(right).normalize();
		const m00 = right.x;
		const m01 = right.y;
		const m02 = right.z;
		const m10 = up2.x;
		const m11 = up2.y;
		const m12 = up2.z;
		const m20 = fwd.x;
		const m21 = fwd.y;
		const m22 = fwd.z;

		const num8 = (m00 + m11) + m22;
		let q: Quaternion = new Quaternion();
		if (num8 > 0) {
			let num = Math.sqrt(num8 + 1);
			q.w = num * 0.5;
			num = 0.5 / num;
			q.x = (m12 - m21) * num;
			q.y = (m20 - m02) * num;
			q.z = (m01 - m10) * num;
			return q;
		}
		if ((m00 >= m11) && (m00 >= m22)) {
			const num7 = Math.sqrt(((1 + m00) - m11) - m22);
			const num4 = 0.5 / num7;
			q.x = 0.5 * num7;
			q.y = (m01 + m10) * num4;
			q.z = (m02 + m20) * num4;
			q.w = (m12 - m21) * num4;
			return q;
		}
		if (m11 > m22) {
			const num6 = Math.sqrt(((1 + m11) - m00) - m22);
			const num3 = 0.5 / num6;
			q.x = (m10 + m01) * num3;
			q.y = 0.5 * num6;
			q.z = (m21 + m12) * num3;
			q.w = (m20 - m02) * num3;
			return q;
		}
		const num5 = Math.sqrt(((1 + m22) - m00) - m11);
		const num2 = 0.5 / num5;
		q.x = (m20 + m02) * num2;
		q.y = (m21 + m12) * num2;
		q.z = 0.5 * num5;
		q.w = (m01 - m10) * num2;
		return q;
	}

	multiply(q: QuaternionLike): Quaternion {
		return this.set(
			this.w * q.x + this.x * q.w + this.y * q.z - this.z * q.y,
			this.w * q.y + this.y * q.w + this.z * q.x - this.x * q.z,
			this.w * q.z + this.z * q.w + this.x * q.y - this.y * q.x,
			this.w * q.w - this.x * q.x - this.y * q.y - this.z * q.z,
		);
	}

	multiplyVec3_ALLOC(v: Vector3Like): Vector3 {
		const q = alloc(new Quaternion())
			.set(v.x, v.y, v.z, 0)
			.multiply(this.conjugate());
		return alloc(new Vector3(q.x, q.y, q.z));
	}

	// creates garbage
	static get_ALLOC(
		x: number | undefined = undefined,
		y: number | undefined = undefined,
		z: number | undefined = undefined,
		w: number | undefined = undefined,
	): QuaternionLike {
		if (x != null && typeof x === 'number') {
			return alloc({ x, y: y!, z: z!, w: w! });
		}
		return alloc({ x: 0, y: 0, z: 0, w: 1 });
	}

	static set(
		q: QuaternionLike,
		x: number | QuaternionLike,
		y: number | undefined = undefined,
		z: number | undefined = undefined,
		w: number | undefined = undefined,
	): QuaternionLike {
		if (x != null && typeof x === 'number') {
			q.x = x;
			q.y = y!;
			q.z = z!;
			q.w = w!;
		} else if ((x as unknown as QuaternionLike).x != null) {
			const qq: QuaternionLike = x as QuaternionLike;
			q.x = qq.x;
			q.y = qq.y;
			q.z = qq.z;
			q.w = qq.w;
		}
		return q;
	}

	// can create garbage
	static multiply(
		a: QuaternionLike,
		b: QuaternionLike,
		overwrite: QuaternionLike | boolean = false,
	): QuaternionLike {
		const x = a.w * b.x + a.x * b.w + a.y * b.z - a.z * b.y;
		const y = a.w * b.y + a.y * b.w + a.z * b.x - a.x * b.z;
		const z = a.w * b.z + a.z * b.w + a.x * b.y - a.y * b.x;
		const w = a.w * b.w - a.x * b.x - a.y * b.y - a.z * b.z;
		return Quaternion.newOrOverwrite(a, overwrite, x, y, z, w);
	}

	static vectorize(a: QuaternionLike) {
		a.w = 0;
		return a;
	}

	// can create garbage
	static invert(
		a: QuaternionLike,
		overwrite: QuaternionLike | boolean = false,
	): QuaternionLike {
		const norm = a.x * a.x + a.y * a.y + a.z * a.z + a.w * a.w;
		const x = -a.x / norm;
		const y = -a.y / norm;
		const z = -a.z / norm;
		const w = a.w / norm;
		return Quaternion.newOrOverwrite(a, overwrite, x, y, z, w);
	}

	// creates garbage
	private static newOrOverwrite(
		a: QuaternionLike,
		overwrite: QuaternionLike | boolean,
		x: number,
		y: number,
		z: number,
		w: number,
	): QuaternionLike {
		if (overwrite === true) {
			a.x = x;
			a.y = y;
			a.z = z;
			a.w = w;
			return a;
		} else if (overwrite) {
			overwrite.x = x;
			overwrite.y = y;
			overwrite.z = z;
			overwrite.w = w;
			return overwrite;
		} else {
			return alloc({ x, y, z, w });
		}
	}

	// allow quaternino pool
	// creates garbage
	static hamiltonMultiply_ALLOC(v: Vector3Like, q: QuaternionLike): QuaternionLike {
		// q v q'
		const q1 = Quaternion.invert(q);
		const p = Quaternion.get_ALLOC(v.x, v.y, v.z, 0);
		const qv = Quaternion.multiply(q, p);
		const qvq1 = Quaternion.multiply(qv, q1);
		return Quaternion.vectorize(qvq1);
	}
	
	static hamiltonMultiply(v: Vector3Like, q: QuaternionLike, dummy: QuaternionLike) {
		const q1 = Quaternion.invert(q);
		const p = dummy
		p.w = 0;
		p.x = v.x;
		p.y = v.y;
		p.z = v.z;
		const qv = Quaternion.multiply(q, p);
		const qvq1 = Quaternion.multiply(qv, q1);
		return Quaternion.vectorize(qvq1);
	}
}
