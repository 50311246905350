import { XRRigidTransform } from '../../Deps/WebXR.ts';
import { XR } from '../../Helpers/XR.ts';
import { Component } from '../../Ontology/Entity/Component.ts';
import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from '../../Helpers/Quaternion.ts';

type XRFollowerData = {
	follow?: 'head' | 'left-hand' | 'right-hand';
};

export class XRFollower extends Component<XRFollowerData> {
	enabled = false;
	_q = new Quaternion(Quaternion.identity);

	create = () => {
	};

	update = () => {
		if (!XR.isActive()) {
			return;
		}

		// 1. Get XR display position and rotation
		if (this.data!.follow === 'head') {
			XR.getHeadsetTransform(this.applyRigidTransform);
		} else if (this.data!.follow === 'left-hand') {
			XR.getHandPose('left', this.applyRigidTransform);
		} else if (this.data!.follow === 'right-hand') {
			XR.getHandPose('right', this.applyRigidTransform);
		}
	};

	applyRigidTransformFlipped = (xrTransform: XRRigidTransform | null) =>
		this.applyRigidTransform(xrTransform, true);

	applyRigidTransform = (
		xrTransform: XRRigidTransform | null,
		flip: boolean | undefined = undefined,
	) => {
		if (xrTransform == null) {
			return;
		}

		this.entity!.transform!.localPosition = xrTransform!.position;
		this.entity!.transform!.localRotation = xrTransform!.orientation;

		// this.entity!.getComponent(Transform)!.mutate((transform) => {
		//   transform.position = xrTransform!.position;
		//   if (flip) {
		//     // Rotate 180 degrees about y-axis
		//     transform.rotation = this._q.set(xrTransform!.orientation).rotateY(
		//       Math.PI,
		//     );
		//   } else {
		//     transform.rotation = xrTransform!.orientation;
		//   }
		//   return transform;
		// });
	};

	getDefaultData = () => ({
		follow: 'head' as const,
	});
}
