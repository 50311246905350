import { GLTFLoader, HTMLMesh, ThreeStats } from '../../Deps/ThreeJSM.ts';
import { Three } from '../../Deps/Three.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';
export type StatsData = {};

const Stats = function () {
	let mode = 0;
	const container = document.createElement('div');
	container.style.cssText =
		'position:fixed;top:0;left:0;cursor:pointer;opacity:0.9;z-index:10000';
	container.addEventListener('click', function (event) {
		event.preventDefault();
		showPanel(++mode % container.children.length);
	}, false);

	//

	function addPanel(panel) {
		container.appendChild(panel.dom);
		return panel;
	}

	function showPanel(id) {
		for (var i = 0; i < container.children.length; i++) {
			(container.children[i] as HTMLElement).style.display = i === id
				? 'block'
				: 'none';
		}

		mode = id;
	}

	//

	let beginTime = (performance || Date).now(),
		prevTime = beginTime,
		frames = 0;

	const fpsPanel = addPanel(Stats.Panel('FPS', '#0ff', '#002'));
	const msPanel = addPanel(Stats.Panel('MS', '#0f0', '#020'));

    let memPanel
	if (self.performance && (self.performance as any).memory) {
        memPanel = addPanel(Stats.Panel('MB', '#f08', '#201'));
	}

	showPanel(0);

	return {
		REVISION: 16,

		dom: container,

		addPanel: addPanel,
		showPanel: showPanel,

		begin: function () {
			beginTime = (performance || Date).now();
		},

		end: function () {
			frames++;

			const time = (performance || Date).now();

			if (time >= prevTime + 1000) {
                msPanel.update(time - beginTime, 200);
				fpsPanel.update((frames * 1000) / (time - prevTime), 100);

				prevTime = time;
				frames = 0;

				if (memPanel) {
					const memory = (performance as any).memory;
					memPanel.update(
						memory.usedJSHeapSize / 1048576,
						memory.jsHeapSizeLimit / 1048576,
					);
				}
			}

			return time;
		},

		update: function () {
			beginTime = this.end();
		},

		// Backwards Compatibility

		domElement: container,
		setMode: showPanel,
	};
};

Stats.Panel = function (name, fg, bg) {
	let min = Infinity, max = 0;
	const round = Math.round;
	const PR = round(window.devicePixelRatio || 1);

	const WIDTH = 80 * PR,
		HEIGHT = 48 * PR,
		TEXT_X = 3 * PR,
		TEXT_Y = 2 * PR,
		GRAPH_X = 3 * PR,
		GRAPH_Y = 15 * PR,
		GRAPH_WIDTH = 74 * PR,
		GRAPH_HEIGHT = 30 * PR;

	const canvas = document.createElement('canvas');
	canvas.width = WIDTH;
	canvas.height = HEIGHT;
	canvas.style.cssText = 'width:80px;height:48px';

	const context = canvas.getContext('2d')!;
	context.font = 'bold ' + (9 * PR) + 'px Helvetica,Arial,sans-serif';
	context.textBaseline = 'top';

	context.fillStyle = bg;
	context.fillRect(0, 0, WIDTH, HEIGHT);

	context.fillStyle = fg;
	context.fillText(name, TEXT_X, TEXT_Y);
	context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);

	context.fillStyle = bg;
	context.globalAlpha = 0.9;
	context.fillRect(GRAPH_X, GRAPH_Y, GRAPH_WIDTH, GRAPH_HEIGHT);

	return {
		dom: canvas,

		update: function (value, maxValue) {
			min = Math.min(min, value);
			max = Math.max(max, value);

			context.fillStyle = bg;
			context.globalAlpha = 1;
			context.fillRect(0, 0, WIDTH, GRAPH_Y);
			context.fillStyle = fg;
			context.fillText(
				round(value) + ' ' + name + ' (' + round(min) + '-' +
					round(max) + ')',
				TEXT_X,
				TEXT_Y,
			);

			context.drawImage(
				canvas,
				GRAPH_X + PR,
				GRAPH_Y,
				GRAPH_WIDTH - PR,
				GRAPH_HEIGHT,
				GRAPH_X,
				GRAPH_Y,
				GRAPH_WIDTH - PR,
				GRAPH_HEIGHT,
			);

			context.fillRect(
				GRAPH_X + GRAPH_WIDTH - PR,
				GRAPH_Y,
				PR,
				GRAPH_HEIGHT,
			);

			context.fillStyle = bg;
			context.globalAlpha = 0.9;
			context.fillRect(
				GRAPH_X + GRAPH_WIDTH - PR,
				GRAPH_Y,
				PR,
				round((1 - (value / maxValue)) * GRAPH_HEIGHT),
			);
		},
	};
};

export class Stats3D extends ThreeJSComponent<StatsData> {
	threeStats: ReturnType<typeof Stats> | null = null;
	threeMesh: Three.Mesh | null = null;
	counter: number = 0;

	createThreeObject = () => {
		this.threeStats = Stats();
		document.body.appendChild(this.threeStats.dom);
		this.threeStats.dom.style.position = 'absolute';
		this.threeStats.dom.style.left = '160px';
		this.threeStats.dom.style.top = '30px';
		this.threeStats.dom.style.width = '80px';
		this.threeStats.dom.style.height = '48px';

		// @ts-ignore
		this.threeMesh = new HTMLMesh(this.threeStats.dom);
		// this.threeMesh!.position.x = - 0.75;
		// this.threeMesh!.position.y = 2;
		// this.threeMesh!.position.z = - 0.6;
		// this.threeMesh!.rotation.y = Math.PI / 4;
		this.threeMesh!.scale.setScalar(2.5);

		return Promise.resolve(this.threeMesh!);
	};

	update = () => {
		// if (++this.counter % 30 === 0) {
		this.threeStats!.update();
		//@ts-ignore
		this.threeMesh!.material.map.update();
		// }
	};
}
