import {
	ComponentBlueprintInstance,
	IEntity,
	IWorld,
	Privacy,
	StringifiedComponentBlueprint,
} from '../Ontology/API.ts';
import { IGaia, IGaiaScene } from './API.ts';
import { IAxon } from '../Axon/API.ts';
import { meta } from '../Ontology/Meta.ts';
import { GaiaScene } from './GaiaScene.ts';
import { deserializeComponentBlueprint } from '../Ontology/Helpers.ts';
import { IScene } from '../Animus/API.ts';
import { Constructor } from '../Helpers/Types.ts';

class Gaia implements IGaia {
	_scene: IGaiaScene | null = null;

	constructor(
		public world: IWorld,
		public scene: IScene,
		public axon: IAxon,
	) {
	}
	showDebugScene = () => {
		if (this._scene) {
			this._scene.show();
		} else {
			this._scene = this.createScene();
		}
	};
	hideDebugScene = () => {
		if (this._scene) {
			this._scene.hide();
		}
	};

	spawn3d = (
		...components: string[]
	): IEntity => {
		return this.spawn('UpdatingEntity', 'ThreeJSTransform', ...components);
	};

	// params components
	spawn = (
		object: string | Constructor<unknown>,
		...components: string[] | Constructor<unknown>[]
	): IEntity => {
		const constructor = typeof object === 'string'
			? meta.getClass(object as string)!
			: object as Constructor<unknown>;
		const pool = meta.getPool(constructor)!;

		let blueprints: ComponentBlueprintInstance<unknown>[] | null = null;
		if (components.length > 0 && typeof components[0] === 'string') {
			// @ts-ignore a
			blueprints = components.map((component) => ({
				component,
				data: null,
			}))
				.map(deserializeComponentBlueprint);
		} else if (components.length > 0) {
			blueprints = components.map((component) => ({
				component: new component(),
				data: null,
			}));
		} else {
			blueprints = null;
		}

		const entity = this.world.spawn(
			pool.take() as IEntity,
			this.scene,
			(blueprints) ? { components: blueprints, parent: null } : null,
			this,
		);
		return entity;
	};

	spawn2 = (
		object: string | Constructor<unknown>,
		privacy: Privacy | undefined,
		blueprint:
			| StringifiedComponentBlueprint<unknown>[]
			| ComponentBlueprintInstance<
				any
			>[],
	): IEntity => {
		const nonNullPrivacy = privacy || 'public';
		if (typeof object === 'string') {
			const constructor = meta.getClass(object as string)!;
			const pool = meta.getPool(constructor)!;
			const entity = this.world.spawn(
				pool.take() as IEntity,
				this.scene,
				blueprint
					? {
						privacy: nonNullPrivacy,
						// @ts-ignore assumptions
						data: blueprint.map(deserializeComponentBlueprint),
					}
					: null,
				this,
			);
			return entity;
		} else {
			const constructor = object as Constructor<unknown>;
			const pool = meta.getPool(constructor)!;
			const entity = this.world.spawn(
				pool.take() as IEntity,
				this.scene,
				blueprint
					? {
						privacy: nonNullPrivacy,
						components: blueprint as ComponentBlueprintInstance<
							unknown
						>[],
						parent: null,
					}
					: null,
				this,
			);
			return entity;
		}
	};

	printEntity = (entity: IEntity) => {
		console.log(entity.id);

		console.log(entity.components.map((x) => ({
			name: x.constructor.name,
			privacy: x.privacy,
			data: x.data,
		})));
	};

	get = (id: string): IEntity | undefined => {
		return this.world.entities.find((entity) => entity.id === id);
	};

	getByName = (name: string): IEntity | IEntity[] | null => {
		const results = this.world.entities.filter((entity) =>
			entity.name === name
		);
		if (results.length === 1) {
			return results[0];
		} else if (results.length > 0) {
			return results;
		} else {
			return null;
		}
	};

	destroy = (entity: IEntity) => {
		entity.destroy(this);
	};

	createScene = (): IGaiaScene => {
		return new GaiaScene(this.world);
	};
}

export { Gaia };
