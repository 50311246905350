import { Component } from '../../Ontology/Entity/Component.ts';
import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion } from '../../Helpers/Quaternion.ts';
import { ITransform } from '../../Ontology/API.ts';

type FollowerData = {
	offsetPosition: Vector3Like;
	offsetRotation: Vector3Like;
	followPosition: boolean;
	followRotation: 'y' | boolean;
};

export class Follower extends Component<FollowerData> {
	enabled = false;
	target: ITransform<any> | null = null;

	v = new Vector3();
	q = new Quaternion();

	v2 = new Vector3();

	create = () => {
	};

	update = () => {
		if (!this.enabled || !this.target) {
			return;
		}

		if (this.data!.followPosition) {
			this.entity!.transform!.position = this.v.set(this.target.position)
				.add(this.data!.offsetPosition);
		}

		// if (this.data!.followRotation === true) {
		// 	this.entity!.transform!.rotation = this.q.set(this.target.rotation);
		// }

		if (this.data!.followRotation === 'y') {
			// this.v2.set(this.target.forward).cross(this.target.up);
			this.entity!.transform!.localRotation = this.q.fromEuler(0, this.target.rotation.y, 0)
			// this.entity!.transform!.localRotation = this.q.set(this.target.localRotation)
		}
	};

	setTarget(target: ITransform<any>) {
		this.enabled = true;
		this.target = target;
	}

	getDefaultData = () => ({
		offsetPosition: Vector3.zero,
		offsetRotation: Vector3.zero,
		followPosition: true,
		followRotation: true,
	});
}
