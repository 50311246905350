import { XR } from './XR.ts';

export enum Key {
	forward,
	left,
	right,
	back,
	sprint,
	grab,
}

export enum Button {
	grabL,
	grabR,
	shootL,
	shootR,
}

export enum ButtonState {
	idle,
	down,
	pressed,
	up,
}

export enum Axis {
	moveX,
	moveZ,
	lookX,
	lookY,
}

// internally:
// keydown, keyup
// frame1: keydown
// frame2: keyup

export class Input {
	// idle -> down -> pressed -> pressed -> up -> idle
	private static keyMapKeys: string[] = [];
	private static keyMap: Map<string, /* eventCode */ Key> = new Map();
	private static buttons: Map<Key | Button, ButtonState> = new Map();
	private static buttonsNext: Map<Key | Button, ButtonState[]> = new Map();

	private static clicking = false;
	private static dragging = false;
	private static lastPointerXY: [number, number] = [0, 0];
	private static deltaXY: [number, number] = [0, 0];

	static _initialize() {
		document.addEventListener('pointerdown', (event) => {
			this.lastPointerXY = [event.clientX, event.clientY];
			this.deltaXY[0] = 0;
			this.deltaXY[1] = 0;
			this.clicking = true;
		});
		document.addEventListener('pointermove', (event) => {
			if (this.clicking) {
				this.dragging = true;
			} else {
				this.dragging = false;
			}
			const deltaX = event.clientX - this.lastPointerXY[0];
			const deltaY = event.clientY - this.lastPointerXY[1];
			this.lastPointerXY[0] = event.clientX;
			this.lastPointerXY[1] = event.clientY;
			this.deltaXY[0] = deltaX;
			this.deltaXY[1] = deltaY;
			// Input.keysNext.set(Key.lookX, [ButtonState.pressed, ButtonState.down]);
			// Input.keysNext.set(Key.lookY, [ButtonState.pressed, ButtonState.down]);
		});

		document.addEventListener('pointerup', (event) => {
			this.clicking = false;
			this.dragging = false;
			this.deltaXY[0] = 0;
			this.deltaXY[1] = 0;
		});

		document.addEventListener('pointercancel', (event) => {
			this.clicking = false;
			this.dragging = false;
			this.deltaXY[0] = 0;
			this.deltaXY[1] = 0;
		});

		document.addEventListener('keydown', (event: KeyboardEvent) => {
			const key = Input.keyMap.get(event.code);
			// qnote('keydown', event.code, key);
			if (key != null) {
				this.addEvent(Input.buttonsNext.get(key)!, ButtonState.down);
			}
		});

		// keep key down until one full frame has passed
		document.addEventListener('keyup', (event: KeyboardEvent) => {
			const key = Input.keyMap.get(event.code);
			// qnote('keyup', event.code, key);
			if (key != null) {
				this.addEvent(Input.buttonsNext.get(key)!, ButtonState.up);
			}
		});

		this.keyMap.set('KeyW', Key.forward);
		this.keyMap.set('KeyA', Key.left);
		this.keyMap.set('KeyS', Key.back);
		this.keyMap.set('KeyD', Key.right);
		this.keyMap.set('ShiftLeft', Key.sprint);

		for (const [_, key] of Input.keyMap) {
			this.buttons.set(key, ButtonState.idle);
			this.buttonsNext.set(key, []);
		}

		this.keyMapKeys = [...this.keyMap.keys()]
	}

	// store: [down, up], [down], [up]
	// collapse: [up, down] -> [down], [up, down, up] -> [down, up], [down, up, down] -> [down]
	// possibilities:
	// down, +down x skip
	// down, +up --> add
	// up, +down xx --> collapse:down
	// up, +up x skip
	static addEvent = (current: ButtonState[], next: ButtonState) => {
		const prev = current[current.length - 1];
		if (current.length == 0) {
			// [] -> [x]
			current.push(next);
		} else if (
			prev == next ||
			(prev == ButtonState.pressed &&
				next == ButtonState.down)
		) {
			// skip duplicate
			// [x] -> [x]
		} else if (
			prev == ButtonState.up &&
			next == ButtonState.down
		) {
			// collapse
			// [up, down] -> [down]
			current.pop();
			current.push(next);
		} else {
			// add
			// [down] -> [down, up]
			// [] -> [x]
			current.push(next);
		}

		// [down, down] --> [down]
		if (
			current.length > 2 &&
			prev == current[current.length - 2]
		) {
			current.pop();
		}
	};

	static _update = () => {
		// if (this.clicking) {
		// 	const [x, y] = this.lastPointerXY;
		// 	this.lastPointerXY[0] = x
		// 	this.lastPointerXY[1] = y
		// }
		if (this.dragging) {
			this.dragging = false;
		} else {
			this.deltaXY[0] = 0;
			this.deltaXY[1] = 0;
		}

		for (let i = 0; i < this.keyMapKeys.length; i++) {
			const key = this.keyMap.get(this.keyMapKeys[i])!;
			// pop latest key off keysNext (get next frame)
			// and update keys (progress)
			const cur = Input.buttons.get(key)!;
			const next = Input.buttonsNext.get(key)!.pop();
			if (
				next && next != cur &&
				!(cur == ButtonState.pressed && next == ButtonState.down)
			) {
				// Button changed, and is not pressed -> down
				// e.g. up -> down, or down -> up
				Input.buttons.set(key, next);
			} else if (
				cur === ButtonState.down &&
				(next === ButtonState.down || next === ButtonState.pressed)
			) {
				// Button was already down, and is still down (down -> pressed)
				Input.buttons.set(key, ButtonState.pressed);
			} else if (
				cur === ButtonState.up &&
				(next == null || next === ButtonState.up ||
					next === ButtonState.idle)
			) {
				// Button was previously released (up -> idle)
				Input.buttons.set(key, ButtonState.idle);
			}
			// else if (!next) {
			// 	Input.keys.set(key, ButtonState.idle);
			// }
		}
	};

	static get mousePosition(): [number, number] {
		return this.lastPointerXY;
	}

	static getKey(key: Key): boolean {
		const state = Input.buttons.get(key);
		return state == ButtonState.down || state == ButtonState.pressed;
	}

	static getKeyDown(key: Key): boolean {
		return Input.buttons.get(key) == ButtonState.down;
	}

	static getKeyUp(key: Key): boolean {
		return Input.buttons.get(key) == ButtonState.up;
	}

	static getButton(btn: Button): boolean {
		if (!XR.isActive()) {
			return this.clicking;
		}
		if (btn === Button.grabL) {
			return XR.getController('left')?.gamepad?.buttons[1]?.pressed ??
				false;
		}
		if (btn === Button.grabR) {
			return XR.getController('right')?.gamepad?.buttons[1]?.pressed ??
				false;
		}
		if (btn === Button.shootL) {
			return XR.getController('left')?.gamepad?.buttons[0]?.pressed ??
				false;
		}
		if (btn === Button.shootR) {
			return XR.getController('right')?.gamepad?.buttons[0]?.pressed ??
				false;
		}

		return false;
	}

	static getAxis(axis: Axis): number {
		if (axis == Axis.lookX) {
			if (XR.isActive()) {
				return XR.getController('right')?.gamepad?.axes[2] ?? 0;
			}
			return this.deltaXY[0];
		} else if (axis === Axis.lookY) {
			if (XR.isActive()) {
				return XR.getController('right')?.gamepad?.axes[3] ?? 0;
			}
			return this.deltaXY[1];
		} else if (axis == Axis.moveZ) {
			if (XR.isActive()) {
				return -(XR.getController('left')?.gamepad?.axes[3] ?? 0);
			}
			return this.getKey(Key.forward)
				? 1
				: this.getKey(Key.back)
				? -1
				: 0;
		} else if (axis === Axis.moveX) {
			if (XR.isActive()) {
				return XR.getController('left')?.gamepad?.axes[2] ?? 0;
			}
			return this.getKey(Key.left) ? -1 : this.getKey(Key.right) ? 1 : 0;
		}
		return 0;
	}

	static snapToCenter = (value: boolean) => {
		// Input.scene.input.setdra
	};
}

// for (const [code, key] of Input.keyMap) {
//     const pressed = pKey.isDown;
//     const state = Input.keys.get(key);
//     if (
//         pressed &&
//         (state === ButtonState.idle || state === ButtonState.up)
//     ) {
//         Input.keys.set(key, ButtonState.down);
//     } else if (pressed && state === ButtonState.down) {
//         Input.keys.set(key, ButtonState.pressed);
//     } else if (
//         !pressed &&
//         (state === ButtonState.down || state === ButtonState.pressed)
//     ) {
//         Input.keys.set(key, ButtonState.up);
//     } else if (!pressed && state === ButtonState.up) {
//         Input.keys.set(key, ButtonState.idle);
//     }
// }

// for (const [btn, state] of Input.buttons) {
//     const pressed =
//         btn === Button.primary
//             ? Input.scene.input.activePointer.primaryDown
//             : false;

//     if (
//         pressed &&
//         (state === ButtonState.idle || state === ButtonState.up)
//     ) {
//         Input.buttons.set(btn, ButtonState.down);
//     } else if (pressed && state === ButtonState.down) {
//         Input.buttons.set(btn, ButtonState.pressed);
//     } else if (
//         !pressed &&
//         (state === ButtonState.down || state === ButtonState.pressed)
//     ) {
//         Input.buttons.set(btn, ButtonState.up);
//     } else if (!pressed && state === ButtonState.up) {
//         Input.buttons.set(btn, ButtonState.idle);
//     }
// }
