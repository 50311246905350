import { Three } from '../Deps/Three.ts';
import { alloc } from './Alloc.ts';
import { Quaternion, QuaternionLike } from './Quaternion.ts';
import { Vector3, Vector3Like } from './Vector3.ts';

export class ThreeJSHelper {
	/// Convert into ThreeJS Quaternion type
	static toQuat = (
		q: QuaternionLike,
		target?: Three.Quaternion,
	): Three.Quaternion => {
		if (target) {
			target.set(q.x, q.y, q.z, q.w);
			return target;
		}
		return alloc(new Three.Quaternion(q.x, q.y, q.z, q.w));
	};

	/// Convert into Cosm Native Quaternion type
	static fromQuat = (q: Three.Quaternion): Quaternion => {
		return alloc(new Quaternion(q.x, q.y, q.z, q.w));
	};

	/// Convert into ThreeJS Vector3 type
	static toVec = (v: Vector3Like, target?: Three.Vector3): Three.Vector3 => {
		if (target) {
			target.set(v.x, v.y, v.z);
			return target;
		}
		return alloc(new Three.Vector3(v.x, v.y, v.z));
	};

	/// Convert into  Native Vector3 type
	static fromVec = (v: Three.Vector3): Vector3 => {
		return alloc(new Vector3(v.x, v.y, v.z));
	};
}
