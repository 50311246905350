import { Component } from '../../Ontology/Entity/Component.ts';
import { Time } from '../../Helpers/Time.ts';
import { GLTFMesh } from './GLTFMesh.ts';
import { Button, Input } from '../../Helpers/Input.ts';

type PlayerHandData = {
	hand: 'left' | 'right';
};

export class PlayerHand extends Component<PlayerHandData> {
	_mesh: GLTFMesh | null = null;

	create = () => {
		this._mesh = this.entity!.getComponent(GLTFMesh);
	};

	update = () => {
		if (
			Input.getButton(
				this.data!.hand === 'left' ? Button.grabL : Button.grabR,
			)
		) {
			// console.log('fire ', this.data?.hand);
		}
	};
}
