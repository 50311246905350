// pool is open

import { Constructor } from "./Types.ts";

export interface IPoolObject<T> {
  take(): T;
  release(): void;
}

export interface IObjectPool<T> {
  take(): T;
  release(instance: T): void;
}

export interface ObjectPoolHelper<T> {
  create: () => T;
  cleanup: (instance: T) => void;
}

export class DefaultObjectPoolHelper<T> implements ObjectPoolHelper<T> {
  constructor(
    private _constructor: Constructor<T>,
    private _constructorArgs: any[] = [],
  ) {}

  create() {
    return new this._constructor(...this._constructorArgs);
  }

  cleanup(_instance: T) {
    // do nothing
  }
}

export class LazyObjectPool<T> implements IObjectPool<T> {
  private _pool: T[] = [];

  constructor(private _helper: ObjectPoolHelper<T>) {
  }

  public take(): T {
    if (this._pool.length > 0) {
      const instance = this._pool.pop()!;
      // Note.info("meta", "reusing object from pool", instance.constructor.name);
      return instance;
    }
    const instance = this._helper.create();
    // Note.info(
    //   "meta",
    //   `Created new instance of ${instance.constructor.name}`,
    //   instance,
    // );
    return instance;
  }

  public release(instance: T): void {
    // Note.trace("meta", "releasing object to pool", instance.constructor.name);
    this._helper.cleanup(instance);
    this._pool.push(instance);
  }
}

export class EagerObjectPool<T> implements IObjectPool<T> {
  private _pool: T[] = [];

  constructor(private helper: ObjectPoolHelper<T>, private size: number) {
    for (let i = 0; i < size; i++) {
      this._pool.push(helper.create());
    }
  }

  public take(): T {
    if (this._pool.length > 0) {
      return this._pool.pop()!;
    }
    return this.helper.create();
  }

  public release(instance: T): void {
    this.helper.cleanup(instance);
    this._pool.push(instance);
  }
}
