import { Cupid } from './Cupid.ts';
import { Peer, PeerEvents } from './peer.ts';
import { getRandomId } from './Cupid/Helpers.ts';
import {
	ConnectRequest,
	DirectedMessage,
	FullMessage,
	Message,
} from './Cupid/Types.ts';
import { Observable } from '../Helpers/Observable.ts';
import { Note } from '../Helpers/Note.ts';
import { alloc } from "../Helpers/Alloc.ts";

const CONFIGURATION = {
	'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }],
};

const UNRELIABLE_CHANNEL_NAME = 'data_unreliable';
const UNRELIABLE_CHANNEL_OPTIONS = {
	ordered: false,
	maxRetransmits: 0,
};

const RELIABLE_CHANNEL_NAME = 'data_reliable';
const RELIABLE_CHANNEL_OPTIONS = {
	ordered: true,
};

const CONNECTION_TIMEOUT = 5000;

enum MessageTypes {
	Answer = 'webrtc-answer',
	Offer = 'webrtc-offer',
	IceCandidate = 'webrtc-ice-candidate',
	Renegotiation = 'webrtc-renegotiation',
	Success = 'webrtc-success',
}

const setStatus = (status: string) => {
	const statusText = document.getElementById('status-text');
	if (statusText != null) statusText.innerText = status;
};
const setMsg = (status: string) => {
	const statusText = document.getElementById('message-text');
	if (statusText != null) statusText.innerText = status;
};

setStatus('⚫️ Idle');

export class WebRTCPeer extends Observable<PeerEvents> implements Peer {
	status: 'connected' | 'connecting' | 'disconnected';
	mode: 'host' | 'client' | null;
	id: string = getRandomId();
	key: string = getRandomId();

	private lobbyId: string | undefined;

	private hostTimer: number | undefined;
	private joinTimer: number | undefined;

	private friendId: string | undefined;
	conn: RTCPeerConnection | undefined;

	private incomingMessages: FullMessage[] = [];
	private outgoingMessages: DirectedMessage[] = [];

	private iceCandidates: RTCIceCandidate[] = [];
	private unreliableChannel: RTCDataChannel | undefined;
	private reliableChannel: RTCDataChannel | undefined;
	private rtcStage:
		| 'idle'
		| 'calling'
		| 'exchanging_ice_candidates'
		| 'connected' = 'idle';
	private connectionTimestamp: number | undefined;

	private queuedReliableMessages: Message[] = [];
	private queuedUnreliableMessages: Message[] = [];

	private queuedIceCandidates: RTCIceCandidate[] = [];

	audioInitialized = false;

	constructor() {
		super();
		this.status = 'disconnected';
		this.rtcStage = 'idle';
		this.mode = null;

		// do on startup
		this.getMedia();
		// this.newPeer();

		// document.querySelector("#sned")!.addEventListener("click", () => {
		//   const text =
		//     (document.querySelector("#sned-text") as HTMLInputElement).value;
		//   this.send("message", text);
		// });
	}

	cleanupChannels() {
		if (this.unreliableChannel) {
			this.unreliableChannel.onopen = null;
		}
		if (this.reliableChannel) {
			this.reliableChannel.onopen = null;
		}
	}

	cleanupMessages() {
		if (this.outgoingMessages.length > 0) {
			Note.warn(
				'axon',
				'dropping outgoing messages',
				this.outgoingMessages.length,
			);
		}
		if (this.incomingMessages.length > 0) {
			Note.warn(
				'axon',
				'dropping incoming messages',
				this.incomingMessages.length,
			);
		}
		this.outgoingMessages = [];
		this.incomingMessages = [];
	}

	cleanupPeer() {
		Note.info('axon', 'cleaning up peer');
		this.cleanupMessages();
		this.cleanupChannels();
		this.emit('cleanup-peer', this.conn);
		this.audioInitialized = false;
		if (this.conn != null) {
			this.conn.close();
			this.conn = undefined;
		}
	}

	stopTimers = () => {
		this.connectionTimestamp = undefined;
		this.hostTimer && clearTimeout(this.hostTimer);
		this.hostTimer = undefined;
		this.joinTimer && clearTimeout(this.joinTimer);
		this.joinTimer = undefined;
	};

	reset = () => {
		this.stopTimers();
		Note.info('axon', 'reset');
		setStatus('⚫️ Disconnected');
		this.emit('disconnected');
		this.status = 'disconnected';
		this.rtcStage = 'idle';
		this.queuedIceCandidates = [];
	};

	newPeer = async () => {
		Note.info('axon', 'newPeer');
		this.cleanupPeer();
		const conn = new RTCPeerConnection(CONFIGURATION);

		this.conn = conn;

		// @ts-ignore for debugging
		window.conn = conn;
		this.emit('new-peer', conn);

		// this.conn!.ontrack = (ev) => {
		//   console.log("Got remote track", ev.track.label);
		//   // this.audioInitialized = true;
		// }

		this.conn!.addEventListener('track', (event) => {
			Note.info('axon', '🎉🎉🎉 got remote audio', event.track.label);
			const audio = document.getElementById('remote-audio');
			const stream = new MediaStream();
			(audio! as HTMLAudioElement).srcObject = stream;
			stream.addTrack(event.track);
			// (audio! as HTMLAudioElement).play()
			// this.audioInitialized
		});

		// use this b/c firefox doesn't support onconnectionstatechange
		conn.oniceconnectionstatechange = async (event: Event) => {
			const target = event.target as RTCPeerConnection;
			if (
				target.iceConnectionState === 'disconnected' ||
				target.iceConnectionState === 'failed'
			) {
				await this.reset();
				await this.connect();
			} else if (target.iceConnectionState === 'connected') {
				this.rtcStage = 'connected';
				this.stopTimers();
			}
		};

		conn.addEventListener('datachannel', (event) => {
			this.handleDataChannel(event.channel);
		});

		conn.addEventListener('icecandidate', (event) => {
			if (!event.candidate) {
				return;
			}
			this.iceCandidates.push(event.candidate);
		});

		// wait 250ms
		await new Promise((resolve) => setTimeout(resolve, 250));
	};

	_cachedMediaStream: MediaStream | undefined;
	getMedia = async () => {
		if (this._cachedMediaStream) {
			return this._cachedMediaStream;
		}
		try {
			this._cachedMediaStream = await navigator.mediaDevices.getUserMedia(
				{
					audio: true,
				},
			);
			return this._cachedMediaStream;
		} catch (err) {
			Note.error('axon', 'issue attaching audio track', err);
			return null;
		}
	};

	attachAudioTrack = async () => {
		if (this.audioInitialized) return;
		Note.info('axon', 'attach audio track');

		const stream = await this.getMedia();
		if (stream == null) {
			Note.error('axon', 'not attaching audio');
			return;
		}

		for (const track of stream.getTracks()) {
			this.conn!.addTrack(track, stream);
			Note.info('axon', 'add track', track.label);
		}

		this.audioInitialized = true;
	};

	checkAvailableServers = async () => {
		const list = await Cupid.list();
		if (this.status === 'disconnected') {
			if (list.length === 0) {
				setStatus('😐 No servers found');
			} else {
				setStatus(`🙂 ${list.length} servers found`);
			}
		}
		return list.length;
	};

	private connectionSuccess = () => {
		if (this.reliableChannel == null || this.unreliableChannel == null) {
			Note.error(
				'axon',
				'connection error, marked successful before channels ready!',
			);
			return;
		}

		const reliableMessages = [...this.queuedReliableMessages];
		this.queuedReliableMessages = [];

		// send queued messages
		reliableMessages.forEach((message) => {
			this.sendInternal(
				message.type,
				message.payload,
				undefined,
				'reliable',
			);
		});

		const unreliableMessages = [...this.queuedUnreliableMessages];
		this.queuedUnreliableMessages = [];

		unreliableMessages.forEach((message) => {
			this.sendInternal(
				message.type,
				message.payload,
				undefined,
				'unreliable',
			);
		});
		setStatus(`🟢 Connected (${this.mode})`);
		this.status = 'connected';
		this.emit('connected', this.mode === 'host');
		Note.info('axon', '✨ connected');
	};

	connect = async () => {
		await this.getMedia();

		setStatus('🔍 Waiting for match...');
		this.stopTimers();
		await this.newPeer();
		this.status = 'disconnected';
		this.rtcStage = 'idle';

		const params: ConnectRequest = {
			peerId: this.id,
		};
		const MAX_TRIES = 1000;
		let i = 0;
		// only do 1x
		while (i++ < MAX_TRIES) {
			const response = await Cupid.connect(params);
			if (response?.type === 'match' && response.role == 'host') {
				this.stopTimers();
				this.connectionTimestamp = Date.now();
				this.mode = 'host';
				this.status = 'connecting';
				// if not found, host
				this.lobbyId = response.lobbyId;
				this.hostTimer = setTimeout(this.host, Cupid.ANNOUNCE_INTERVAL);
				Note.info(
					'axon',
					'👀 potential client',
					this.lobbyId,
					response.peerId,
				);
				setStatus(`👀 Found friend, hosting`);
			} else if (
				response?.type === 'match' && response.role == 'client'
			) {
				this.stopTimers();
				this.connectionTimestamp = Date.now();
				// found, join
				// await this.attachAudioTrack();
				this.mode = 'client';
				this.status = 'connecting';
				this.lobbyId = response.lobbyId;
				this.joinTimer = setTimeout(this.join, Cupid.JOIN_INTERVAL);
				Note.info('axon', '👀 potential host', response.lobbyId);
				setStatus(`👀 Found friend, joining...`);
			} else {
				// wait
				await new Promise((resolve) =>
					setTimeout(resolve, Cupid.CONNECT_INTERVAL)
				);
				continue;
			}
			break;
		}
	};

	private host = async () => {
		// console.log(this);
		if (
			this.mode === 'client' ||
			this.status === 'connected' ||
			this.connectionTimestamp === undefined ||
			!this.lobbyId
		) {
			// only self can host
			return;
		}

		Note.info('axon', 'hosting...');

		if (Date.now() - this.connectionTimestamp! > CONNECTION_TIMEOUT) {
			setStatus('😢 Connection timed out');
			Note.info('axon', '💔 connection timed out');
			await this.reset();
			await this.connect();
			return;
		}

		if (this.rtcStage === 'exchanging_ice_candidates') {
			this.iceCandidates.forEach((x) => {
				this.cupidSendTo(
					this.friendId!,
					MessageTypes.IceCandidate,
					JSON.stringify({ candidate: x }),
				);
			});
			this.iceCandidates = [];
		}

		const numOutgoingMessages = this.outgoingMessages.length;
		if (numOutgoingMessages > 0) {
			const numIceCandidates = this.outgoingMessages.filter((x) =>
				x.type === MessageTypes.IceCandidate
			).length;
			if (numIceCandidates > 0) {
				Note.info(
					'axon',
					`✉️ sending ice candidates (${numIceCandidates})`,
				);
			}
			if (
				this.outgoingMessages.find((x) =>
					x.type === MessageTypes.Answer
				)
			) {
				Note.info('axon', `✉️ sending answer`);
			}
			if (
				this.outgoingMessages.find((x) =>
					x.type === MessageTypes.Offer
				)
			) {
				Note.error('axon', `✉️ dafuq sending offer`);
				return;
			}
			Note.info(
				'axon',
				`✉️ sending ${this.outgoingMessages.length} messages`,
			);
		}

		const response = await Cupid.host({
			lobbyId: this.lobbyId,
			peerId: this.id,
			key: this.key,
			readIds: this.incomingMessages.map((m) => m.id),
			messages: this.outgoingMessages,
		});

		// setMsg(`hostd ${this.lobbyId} ${jsonText(response)}`);

		if (this.rtcStage === 'idle') {
			setStatus('🌐 Hosting. Waiting for friend...');
		}

		if (response != null) {
			this.outgoingMessages.splice(0, numOutgoingMessages);

			if (
				response != true && response.inboundMessages != null &&
				response.inboundMessages.length > 0
			) {
				this.incomingMessages = response.inboundMessages;
				this.incomingMessages.forEach((m) => {
					this.onCupidReceive(m);
				});
			}
		} else {
			this.status = 'disconnected';
		}

		this.hostTimer && clearTimeout(this.hostTimer);
		this.hostTimer = setTimeout(this.host, Cupid.ANNOUNCE_INTERVAL);
	};

	private join = async () => {
		if (this.status === 'connected' || !this.lobbyId) {
			return;
		}

		Note.info('axon', 'joining...');

		if (Date.now() - this.connectionTimestamp! > CONNECTION_TIMEOUT) {
			setStatus('😢 Connection timed out');
			Note.info('axon', '💔 connection timed out');
			await this.reset();
			await this.connect();
			return;
		}

		if (this.rtcStage === 'idle') {
			await this.newPeer();
			await this.makeCall();
			this.rtcStage = 'calling';
		}

		if (this.rtcStage === 'exchanging_ice_candidates') {
			this.iceCandidates.forEach((c) => {
				this.cupidSend(
					MessageTypes.IceCandidate,
					JSON.stringify({
						candidate: c,
					}),
				);
			});
			this.iceCandidates = [];
		}

		const numOutgoingMessages = this.outgoingMessages.length;

		if (numOutgoingMessages > 0) {
			const numIceCandidates = this.outgoingMessages.filter((x) =>
				x.type === MessageTypes.IceCandidate
			).length;
			if (numIceCandidates > 0) {
				Note.info(
					'axon',
					`✉️ sending ice candidates (${numIceCandidates})`,
				);
			}
			if (
				this.outgoingMessages.find((x) =>
					x.type === MessageTypes.Offer
				)
			) {
				Note.info('axon', `✉️ sending offer`);
			}
			if (
				this.outgoingMessages.find((x) =>
					x.type === MessageTypes.Answer
				)
			) {
				Note.error('axon', `✉️ dafuq sending answer`);
				return;
			}
			Note.info('axon', `✉️ sending ${numOutgoingMessages} messages`);
		}

		const response = await Cupid.join({
			lobbyId: this.lobbyId,
			peerId: this.id,
			messages: this.outgoingMessages,
			readIds: this.incomingMessages.map((m) => m.id),
		});

		// setMsg(`Joining ${this.lobbyId} ${jsonText(response)}`);

		if (response != null) {
			// clear out inbound/outbound queues
			this.outgoingMessages.splice(0, numOutgoingMessages);
			const messages = response.messages;

			if (messages != null && messages.length > 0) {
				this.incomingMessages = messages;
				this.incomingMessages.forEach((message) => {
					this.onCupidReceive(message);
				});
			}
		}

		this.joinTimer && clearTimeout(this.joinTimer);
		this.joinTimer = setTimeout(this.join, Cupid.JOIN_INTERVAL);
	};

	private handleDataChannel = (channel: RTCDataChannel) => {
		if (
			channel.label !== UNRELIABLE_CHANNEL_NAME &&
			channel.label !== RELIABLE_CHANNEL_NAME
		) {
			Note.warn('axon', 'Unknown channel', channel.label);
			return;
		}

		channel.onmessage = async (event) => {
			setMsg(`Received ${event.data}`);
			try {
				const message = JSON.parse(event.data);
				if (message.type === MessageTypes.Renegotiation) {
					// Handle renegotiation
					const { payload } = message;
					console.warn('received reneg', payload);

					this.conn!.setRemoteDescription(
						new RTCSessionDescription(
							payload.offer ?? payload.answer,
						),
					);
					if (payload.offer) {
						const answer = await this.conn!.createAnswer();
						await this.conn!.setLocalDescription(answer);
						// this.send(MessageTypes.Renegotiation, { answer });
					}
				} else {
					this.emit('message', message);
				}
			} catch (e) {
				console.error(e);
			}
		};

		if (channel.label === UNRELIABLE_CHANNEL_NAME) {
			this.unreliableChannel = channel;
		} else if (channel.label === RELIABLE_CHANNEL_NAME) {
			this.reliableChannel = channel;
		}
		Note.info('axon', 'setup channel', channel.label, channel.id);

		if (this.reliableChannel != null && this.unreliableChannel != null) {
			//   await waitUntilX(() =>
			//   this.reliableChannel != null && this.unreliableChannel != null
			// );
			this.connectionSuccess();
		}
	};

	send = (
		type: string,
		payload: unknown,
		reliability: 'reliable' | 'unreliable' = 'reliable',
	) => {
		this.sendInternal(type, payload, undefined, reliability);
	};

	sendTo = (
		peerId: string,
		type: string,
		payload: unknown,
		reliability: 'reliable' | 'unreliable' = 'reliable',
	) => {
		this.sendInternal(type, payload, [peerId], reliability);
	};

	private sendInternal = (
		type: string,
		payload: unknown,
		targets: string[] | undefined,
		reliability: 'reliable' | 'unreliable' = 'reliable',
	) => {
		let message;

		if (targets) {
			message = {
				targets,
				type,
				payload,
			};
		} else {
			message = {
				type,
				payload,
			};
		}

		if (this.status !== 'connected') {
			// Drop queued for now
			Note.error('axon', 'Dropping message', type);
			// if (reliability === "reliable") {
			//   this.queuedReliableMessages.push(message);
			// } else {
			//   this.queuedUnreliableMessages.push(message);
			// }
			return;
		}

		const channel = reliability === 'reliable'
			? this.reliableChannel
			: this.unreliableChannel;

		if (channel == null) {
			Note.error('axon', 'No channel for ', type);
			return;
		}

		channel.send(alloc(JSON.stringify(message)));
	};

	cupidSendTo = (peerId: string, type: string, payload: string) => {
		// Note.info("axon", "sending to", peerId, type, payload);
		this.outgoingMessages.push({
			type,
			payload,
			targets: [peerId],
		});
	};

	cupidSend = (type: string, payload: string) => {
		// Note.info("axon", "sending", type, payload);
		this.outgoingMessages.push({
			type,
			payload,
		});
	};

	private makeCall = async () => {
		Note.info('axon', 'calling ' + this.lobbyId);

		// This happesn only as client
		setStatus('🟠 Calling!');

		await this.attachAudioTrack();

		this.cleanupChannels();

		// do on first join
		const unreliable = this.conn!.createDataChannel(
			UNRELIABLE_CHANNEL_NAME,
			UNRELIABLE_CHANNEL_OPTIONS,
		);

		const reliable = this.conn!.createDataChannel(
			RELIABLE_CHANNEL_NAME,
			RELIABLE_CHANNEL_OPTIONS,
		);

		unreliable.onopen = () => {
			this.handleDataChannel(unreliable);
		};

		reliable.onopen = () => {
			this.handleDataChannel(reliable);
		};

		const offer = await this.conn!.createOffer({
			offerToReceiveAudio: true,
		});
		await this.conn!.setLocalDescription(offer);

		this.cupidSend(MessageTypes.Offer, JSON.stringify({ offer }));

		this.rtcStage = 'calling';
	};

	private onReceiveAnswer = async (message: FullMessage) => {
		Note.info('axon', '✅ got answer');
		this.connectionTimestamp = Date.now();
		this.mode = 'client';
		setStatus(`🟡 Connecting (${this.mode})`);
		this.friendId = message.sender;

		const { answer }: { answer: RTCSessionDescriptionInit } = JSON.parse(
			message.payload as string,
		);

		try {
			const desc = new RTCSessionDescription(answer);
			await this.conn!.setRemoteDescription(desc);
		} catch (e) {
			Note.error('axon', 'handling answer failed', e);
			return;
		}

		// this.send(MessageTypes.Renegotiation, { description: this.conn!.localDescription });

		if (this.rtcStage !== 'exchanging_ice_candidates') {
			this.rtcStage = 'exchanging_ice_candidates';
		}

		if (this.queuedIceCandidates.length > 0) {
			try {
				this.queuedIceCandidates.forEach((candidate) => {
					this.conn!.addIceCandidate(candidate);
					Note.info('axon', '🟢 added ice');
				});
			} catch (e) {
				Note.error('axon', 'adding ice candidates failed', e);
			}
			Note.info(
				'axon',
				`✅ added ice candidates (${this.queuedIceCandidates.length})`,
			);
			this.queuedIceCandidates = [];
		}
	};

	private onReceiveOffer = async (message: FullMessage) => {
		Note.info('axon', '✅ got offer');
		this.connectionTimestamp = Date.now();
		this.mode = 'host';
		setStatus(`🟡 Connecting (${this.mode})`);
		this.friendId = message.sender;

		const { offer }: { offer: RTCSessionDescriptionInit } = JSON.parse(
			message.payload as string,
		);

		await this.attachAudioTrack();

		try {
			Note.info('axon', '(1) setting remote description');
			const desc = new RTCSessionDescription(offer);
			await this.conn!.setRemoteDescription(desc);
			const answer = await this.conn!.createAnswer({
				offerToReceiveAudio: true,
			});
			await this.conn!.setLocalDescription(
				new RTCSessionDescription(answer),
			);

			this.cupidSendTo(
				message.sender,
				MessageTypes.Answer,
				JSON.stringify({ answer }),
			);
			Note.info('axon', '(2) answer queued');

			this.rtcStage = 'exchanging_ice_candidates';
		} catch (e) {
			Note.error('axon', 'handling offer failed', e);
			return;
		}
	};

	private onReceiveIce = (message: FullMessage) => {
		this.connectionTimestamp = Date.now();
		// if (this.rtcStage !== "exchanging_ice_candidates") {
		//   Note.error(
		//     "axon",
		//     "got ice but not in exchanging_ice_candidates. this is handled though",
		//   );
		// }
		try {
			const payload = JSON.parse(message.payload as string);
			if (this.rtcStage === 'exchanging_ice_candidates') {
				this.conn!.addIceCandidate(payload.candidate);
				Note.info('axon', '🟢 added ice');
			} else {
				this.queuedIceCandidates.push(payload.candidate);
			}
		} catch (e) {
			Note.error('axon', 'handling ice failed', e);
			return;
		}
	};

	private onReceiveSuccess = (message: FullMessage) => {
		const payload = JSON.parse(message.payload as string);
	};

	private onCupidReceive = async (message: FullMessage) => {
		if (message.type === MessageTypes.Offer) {
			await this.onReceiveOffer(message);
		} else if (message.type === MessageTypes.Answer) {
			await this.onReceiveAnswer(message);
		} else if (message.type === MessageTypes.IceCandidate) {
			await this.onReceiveIce(message);
		} else if (message.type === MessageTypes.Success) {
			await this.onReceiveSuccess(message);
		} else {
			// this.onMessage && this.onMessage(message);
		}
	};
}

// connectOld = async () => {
//   setStatus("🔍 Looking for server...");
//   this.status = "disconnected";
//   this.rtcStage = "idle";

//   if (this.type !== "self") {
//     // only self can connect
//     return;
//   }
//   // first, try to find server and join
//   const lobby = await Cupid.first();
//   if (lobby) {
//     setStatus(`🔍 Found server, joining...`);
//     this.connectionTimestamp = Date.now();
//     this.lobbyId = lobby.id;
//     this.joinTimer && clearTimeout(this.joinTimer);
//     this.joinTimer = setTimeout(this.join, Cupid.JOIN_INTERVAL);
//     return;
//   } else {
//     setStatus("🙃 No server found, hosting...");
//     // if not found, host
//     this.status = "hosting";
//     this.lobbyId = getRandomId();
//     this.hostTimer && clearTimeout(this.hostTimer);
//     this.hostTimer = setTimeout(this.host, Cupid.ANNOUNCE_INTERVAL);
//   }
// };
