import { Observable } from "../Helpers/Observable.ts";
import { AxonEvents, AxonMessage, IAxon } from "./API.ts";
import { WebRTCPeer } from "./WebRTCPeer.ts";

class Axon extends Observable<AxonEvents> implements IAxon {
  peer = new WebRTCPeer();

  init = () => {
    this.peer.subscribe("message", this.receive);
    this.peer.subscribe("connected", this.connected);
    this.peer.subscribe("disconnected", this.disconnected);
  };

  connect = async (): Promise<void> => {
    return await this.peer.connect();
  };

  send = async (message: AxonMessage): Promise<void> => {
    if (this.peer.status !== "connected") {
      // drop if not connected
      return;
    }
    if (!message.targets || message.targets.length === 0) {
      await this.peer.send(message.type, message.payload, 'unreliable');
    } else {
      for (let i = 0; i < message.targets.length; i++) {
        const target = message.targets[i];
        await this.peer.sendTo(target, message.type, message.payload, 'unreliable');
      }
    }

    this.emit("send", message);
  };

  receive = (message: AxonMessage): void => {
    this.emit("receive", message);
  };

  private connected = (isHost: boolean): void => {
    this.emit("connected", isHost);
  };

  private disconnected = (): void => {
    this.emit("disconnected");
  };
}

const axon = new Axon();
axon.init();

export { axon as Axon };
