import { uuidLong } from '../Helpers/Uuid.ts';
import { Constructor } from '../Helpers/Types.ts';
import type {
	ComponentBlueprintData,
	EntityBlueprintInstance,
	EntityEvents,
	GlobalEntityEvents,
	IComponent,
	IEntity,
	ITransform,
	ITransformData,
	Privacy,
} from './API.ts';
import { meta } from './Meta.ts';
import { Observable } from '../Helpers/Observable.ts';
import { IScene } from '../Animus/API.ts';
import { Note, qnote } from '../Helpers/Note.ts';
import { ThreeJSTransform } from '../Animus/Components/ThreeJSTransform.ts';

type EntityEventFn = (entity: IEntity, propagator: unknown | undefined) => void;
type ComponentEventFn = (
	entity: IEntity,
	component: IComponent<unknown>,
	propagator: unknown | undefined,
) => void;

class GlobalEntityInstance extends Observable<GlobalEntityEvents> {
}

export const GlobalEntity = new GlobalEntityInstance();

export class Entity extends Observable<EntityEvents> implements IEntity {
	scene: IScene | undefined;
	name: string | undefined;
	super: Entity;
	owner: IEntity | null = null;
	isOwner = true;

	get transform(): ITransform<ITransformData> | null {
		// TODO: Fix this but idc
		return this.getComponent(ThreeJSTransform) as unknown as ITransform<
			ITransformData
		>;
	}

	parent: IEntity | null = null;
	children: IEntity[] = [];

	constructor(
		public readonly tags: string[] | undefined = undefined,
	) {
		super();
		this.name = 'UNINITIALIZED';
		this.id = 'UNINITIALIZED';
		this.super = {
			...this,
		};
	}

	id: string;
	privacy: Privacy = 'public';
	state: 'alive' | 'dead' = 'dead';
	updating = false;
	components: IComponent<unknown>[] = [];

	createOverridable = () => {
	};

	create = (
		blueprint: EntityBlueprintInstance | null,
		scene: IScene,
		propagator: unknown | undefined,
	): IEntity => {
		this.id = uuidLong();
		this.privacy = blueprint?.privacy ?? 'public';
		this.scene = scene;
		let defaultBlueprint: EntityBlueprintInstance | null = null;
		let blueprintOrDefault = blueprint;
		if (!blueprintOrDefault) {
			defaultBlueprint = this.getDefaultBlueprint();
			blueprintOrDefault = defaultBlueprint;
		}
		this.name = blueprintOrDefault?.name ||
			blueprintOrDefault.entity?.constructor.name ||
			this.constructor.name ||
			'unnamed';

		// Copy over ID
		if (blueprintOrDefault.id) {
			this.id = blueprintOrDefault.id;
		}

		this.state = 'alive';

		let index = 0;
		// Copy over components
		for (
			const { component, privacy, data }
				of blueprintOrDefault!.components || []
		) {
			const componentOrDefault = component ??
				(defaultBlueprint || this.getDefaultBlueprint())?.components
					?.[index]
					?.component;

			const newComponent = componentOrDefault._internal_Create(
				this,
				{
					privacy,
					data,
				},
				propagator,
			);
			newComponent.privacy =
				blueprintOrDefault?.components?.[index]?.privacy ??
					'public';
			newComponent.isOwner = this.isOwner;
			this.components.push(newComponent);
			newComponent.subscribe('mutate', this.onComponentMutate);
			index++;
		}

		for (const child of blueprintOrDefault.children || []) {
			const childEntity = child.entity ?? new Entity();
			childEntity.parent = this;
			childEntity.create(child, scene, propagator);
			this.children.push(childEntity);
		}

		this.createOverridable();
		GlobalEntity.emit('create', this, propagator);

		for (const component of this.components) {
			GlobalEntity.emit('component-create', this, component, propagator);
		}

		return this;
	};

	describe = (): EntityBlueprintInstance => {
		return {
			id: this.id,
			privacy: this.privacy,
			parent: this.parent,
			children: this.children.map((child) => child.describe()),
			components: this.components.flatMap((
				component,
			) => (component.privacy === 'public'
				? [{
					component,
					data: component.describe(),
				}]
				: [])
			),
		};
	};

	destroy = (propagator: unknown): void => {
		Note.trace('_', 'Entity.destroy', this.name, this.id);
		for (const component of this.components) {
			component.destroy(propagator);
		}
		for (const child of this.children) {
			child.destroy(propagator);
		}
		GlobalEntity.emit('destroy', this, propagator);
		// this.emit("destroy", this, propagator);
		this.parent = null;
		this.components = [];
		this.state = 'dead';
		this.children = [];
		this.name = 'UNINITIALIZED';
		this.id = 'UNINITIALIZED';
		meta.getPool(this.constructor as any)!.release(this);
	};

	mutate = (
		fn: (entity: IEntity) => void,
		propagator: unknown | undefined,
	): IEntity => {
		fn(this);
		GlobalEntity.emit('mutate', this, propagator);
		return this;
	};

	getChildByName = (name: string): IEntity | undefined => {
		for (let i = 0; i < this.children.length; i++) {
			if (this.children[i].name === name) {
				return this.children[i];
			}
		}
		return undefined;
	};

	getAllComponents = <TComponent extends IComponent<any>>(
		component: Constructor<TComponent> | string,
	): TComponent[] => {
		const results: TComponent[] = [];
		if (typeof component === 'string') {
			for (let i = 0; i < this.components.length; i++) {
				if (this.components[i].constructor.name === component) {
					results.push(this.components[i] as TComponent);
				}
			}
		} else {
			for (let i = 0; i < this.components.length; i++) {
				const c = this.components[i];
				if (
					c instanceof
						(component as unknown as Constructor<TComponent>) ||
					((c as any).constructor.prototype instanceof
						(component as unknown as Constructor<TComponent>))
				) {
					results.push(c as TComponent);
				}
			}
		}
		return results;
	};

	getComponent = <TComponent extends IComponent<any>>(
		component: Constructor<TComponent> | string,
	): (TComponent | null) => {
		if (typeof component === 'string') {
			for (let i = 0; i < this.components.length; i++) {
				if (this.components[i].constructor.name === component) {
					return this.components[i] as TComponent;
				}
			}
		}
		for (let i = 0; i < this.components.length; i++) {
			const c = this.components[i];
			if (
				c instanceof
					(component as unknown as Constructor<TComponent>) ||
				((c as any).constructor.prototype instanceof
					(component as unknown as Constructor<TComponent>))
			) {
				return this.components[i] as TComponent;
			}
		}
		return null;
	};

	getData = <TData>(
		component: Constructor<IComponent<any>> | string,
	): TData | undefined => {
		const componentInstance = this.getComponent(component);
		return componentInstance?.data;
	};

	addComponent = <TComponent extends IComponent<any>>(
		component: Constructor<TComponent>,
		blueprint: ComponentBlueprintData<any> | null,
		propagator: unknown | undefined = undefined,
	): TComponent | undefined => {
		const componentInstance = new component();
		if (componentInstance) {
			componentInstance.isOwner = this.isOwner;
			const c = (componentInstance as TComponent)._internal_Create(
				this,
				blueprint,
				propagator,
			) as TComponent;
			this.components.push(c);
			GlobalEntity.emit('component-create', this, c, propagator);
			return c;
		}
		return undefined;
	};

	removeComponent = <TComponent extends IComponent<any>>(
		component: Constructor<TComponent>,
		propagator: unknown | undefined = undefined,
	): TComponent | undefined => {
		const componentInstance = this.getComponent(component);
		if (componentInstance) {
			componentInstance._internal_Destroy(propagator);
			this.components = this.components.filter(
				(c) => c !== componentInstance,
			);
			return componentInstance;
		}
		return undefined;
	};

	update = () => {
		for (let i = 0; i < this.components.length; i++) {
			const c = this.components[i];
			if (c.state === 'alive') {
				c.update?.();
			}
		}
	};

	getDefaultBlueprint = (): EntityBlueprintInstance => {
		return {
			components: [],
			parent: null,
		};
	};

	onComponentMutate = (
		component: IComponent<unknown>,
		data: unknown,
		propagator: unknown | undefined,
	): void => {
		GlobalEntity.emit(
			'component-mutate',
			this,
			component,
			data,
			propagator,
		);
	};

	setParent = (
		parent: IEntity | null,
		propagator: unknown | undefined,
	): IEntity => {
		this.parent = parent;
		GlobalEntity.emit('set-parent', this, parent, propagator);
		return this;
	};
}

export class UpdatingEntity extends Entity {
	updating = true;
}

export class StaticEntity extends Entity {
	updating = false;
}
