export const isNonZero = (value: number, epsilon = 0.001): boolean => {
	return Math.abs(value) > epsilon;
};


// [[a, b, c], [d, e, f]] * [x, y, z] = [a*x + b*y + c*z, d*x + e*y + f*z]
export const matmul = (a: number[][], b: number[]): number[] => {
	if (a[0].length != b.length) {
		throw new Error(`matmul: incompatible dimensions: ${a[0].length} != ${b.length}`);
	}
	const result: number[] = [];
	for (let i = 0; i < a.length; i++) {
		let sum = 0;
		for (let j = 0; j < a[i].length; j++) {
			sum += (a[i][j] * b[j])
		}
		result.push(sum);
	}
	return result;
}