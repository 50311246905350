import './Extensions.ts';
import {
	XRFrame,
	XRInputSource,
	XRReferenceSpace,
	XRRigidTransform,
	XRSession,
} from '../Deps/WebXR.ts';
import { Observable } from './Observable.ts';

export type XRStatusEvents = 'xr-started' | 'xr-stopped' | 'xr-ended';

export class XRButton {
	static async createButton(renderer) {
		const button = document.createElement('button');

		function showEnterVR() {
			let currentSession: XRSession | null = null;
			async function onSessionStarted(session) {
				session.addEventListener('end', onSessionEnded);
				await renderer.xr.setSession(session);
				button.textContent = 'EXIT VR';
				currentSession = session;
			}
			function onSessionEnded() {
				currentSession?.removeEventListener('end', onSessionEnded);
				button.textContent = 'ENTER VR';
				currentSession = null;
			}
			button.style.display = '';
			button.style.cursor = 'pointer';
			button.style.left = 'calc(50% - 50px)';
			button.style.width = '100px';
			button.textContent = 'ENTER VR';
			button.onmouseenter = function () {
				button.style.opacity = '1.0';
			};
			button.onmouseleave = function () {
				button.style.opacity = '0.5';
			};
			button.onclick = async function () {
				if (currentSession === null) {
					onSessionStarted(await XR.requestSession());
				} else {
					currentSession.end();
				}
			};
		}

		function disableButton() {
			button.style.display = '';
			button.style.cursor = 'auto';
			button.style.left = 'calc(50% - 75px)';
			button.style.width = '150px';
			button.onmouseenter = null;
			button.onmouseleave = null;
			button.onclick = null;
		}
		function showWebXRNotFound() {
			disableButton();
			button.textContent = 'VR NOT SUPPORTED';
		}
		function stylizeElement(element) {
			element.style.position = 'absolute';
			element.style.bottom = '20px';
			element.style.padding = '12px 6px';
			element.style.border = '1px solid #fff';
			element.style.borderRadius = '4px';
			element.style.background = 'rgba(0,0,0,0.1)';
			element.style.color = '#fff';
			element.style.font = 'normal 13px sans-serif';
			element.style.textAlign = 'center';
			element.style.opacity = '0.5';
			element.style.outline = 'none';
			element.style.zIndex = '999';
		}
		if ('xr' in navigator) {
			button.id = 'VRButton';
			button.style.display = 'none';
			stylizeElement(button);
			const supported = await XR.isSupported();
			if (supported) {
				showEnterVR();
			} else {
				return null;
				// showWebXRNotFound();
			}
			return button;
		} else {
			return null;
			// const message = document.createElement('a');
			// if (globalThis.window.isSecureContext === false) {
			// 	message.href = document.location.href.replace(
			// 		/^http:/,
			// 		'https:',
			// 	);
			// 	message.innerHTML = 'WEBXR NEEDS HTTPS';
			// } else {
			// 	message.href = 'https://immersiveweb.dev/';
			// 	message.innerHTML = 'WEBXR NOT AVAILABLE';
			// }
			// message.style.left = 'calc(50% - 90px)';
			// message.style.width = '180px';
			// message.style.textDecoration = 'none';
			// stylizeElement(message);
			// return message;
		}
	}
}

export class XR {
	static session: XRSession | null = null;
	static frame: XRFrame | null = null;

	static worldReferenceSpace: XRReferenceSpace | null = null;
	static headsetTransform: XRRigidTransform | null = null;
	static handTransforms: { [key: string]: XRRigidTransform } = {};

	static subscriptions: Observable<XRStatusEvents> = new Observable();

	static async isSupported(): Promise<boolean> {
		if ('xr' in globalThis.window.navigator) {
			return await globalThis.window.navigator.xr.isSessionSupported(
				'immersive-vr',
			);
		}
		return false;
	}

	static async isEnabled(): Promise<boolean> {
		return await XR.isSupported();
	}

	static isActive(): boolean {
		return XR.session != null;
	}

	static async requestSession(): Promise<XRSession | null> {
		if (!XR.isEnabled()) {
			return null;
		}
		if (this.session != null) {
			return this.session;
		}
		const sessionInit = {
			optionalFeatures: [
				'local-floor',
				'bounded-floor',
				'hand-tracking',
				'layers',
			],
		};
		this.session = await globalThis.window.navigator.xr.requestSession(
			'immersive-vr', // @ts-ignore this is required
			sessionInit,
		);
		
		// @ts-ignore This exists for Oculus
		this.session.updateTargetFrameRate &&
		// @ts-ignore This exists for Oculus
			await this.session.updateTargetFrameRate(90);
		return this.session;
	}

	static updateXRFrame(frame: XRFrame | null) {
		this.headsetTransform = null;
		this.handTransforms['left'] && delete this.handTransforms['left'];
		this.handTransforms['right'] && delete this.handTransforms['right'];
		this.frame = frame;
	}

	static getXRFrame(): XRFrame | null {
		return this.frame;
	}

	static async getHeadsetTransform(
		callback?: (XRRigidTransform) => void,
	): Promise<void> {
		if (this.headsetTransform) {
			callback?.(this.headsetTransform);
			return;
		}

		const session = await XR.requestSession();
		if (this.worldReferenceSpace == null) {
			this.worldReferenceSpace = await this.session!
				.requestReferenceSpace(
					'unbounded',
				).catch(async () =>
					await this.session!.requestReferenceSpace('bounded-floor')
				)
				.catch(async () =>
					await this.session!.requestReferenceSpace('local-floor')
				)
				.catch(async () =>
					await this.session!.requestReferenceSpace('local')
				)
				.catch(() => null);
		}
		if (!session || !this.worldReferenceSpace) {
			return;
		}

		const viewerPose = this.frame!.getViewerPose(this.worldReferenceSpace);

		this.headsetTransform = viewerPose!.transform;
		callback?.(this.headsetTransform);
	}

	static getHandPose(
		key: 'left' | 'right',
		callback?: (transform: XRRigidTransform) => void,
	): void {
		if (!this.session || !this.frame || !this.worldReferenceSpace) {
			return;
			// return Promise.resolve(null);
		}
		if (this.handTransforms && this.handTransforms[key] != null) {
			callback?.(this.handTransforms[key]);
			return;
			// return Promise.resolve(this.handTransforms[key]);
		}

		for (const inputSource of this.session.inputSources) { // we loop through every input source (controller) caught by our session
			if (inputSource.gripSpace) { // we check if our controllers actually have their space
				const gripPose = this.frame.getPose(
					inputSource.gripSpace,
					this.worldReferenceSpace!,
				); // we get controller's pose, by comparing our controller's space to our referance space
				if (gripPose) { // we check if our controller's pose was gotten correctly
					this.handTransforms[inputSource.handedness] =
						gripPose.transform; // inputSource.handedness returns a string representing in which hand we have our controller - that is "left" or "right". Which means that controllers.left and controllers.right will from now on contain an element named "pose", which will simply be their corresponding XRPose
				}
			}
		}
		callback?.(this.handTransforms[key]);
	}

	static getController(key: 'left' | 'right'): XRInputSource | null {
		if (!this.session || !this.frame) {
			return null;
		}
		for (const inputSource of this.session.inputSources) {
			if (inputSource.handedness === key) {
				return inputSource;
			}
		}
		return null;
	}
}
