import { Three } from '../../Deps/Three.ts';
import { IThreeJSObject3D } from '../API.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';

type CubeRendererProps = {
	color: number;
};

export class CubeRenderer extends ThreeJSComponent<CubeRendererProps> implements IThreeJSObject3D {
	_threeObject: Three.Object3D | null = null;

	createThreeObject = (): Promise<Three.Object3D> => {
		const geometry = new Three.BoxGeometry(
			0.5,
			0.5,
			0.5,
		);

		// random hex color
		const material = new Three.MeshLambertMaterial({
			color: this.data?.color ?? this.getRandomColor(),
		});
		const cube = new Three.Mesh(geometry, material);
		cube.name = 'cube';
		return Promise.resolve(cube);
	};

	getRandomColor = (): number => {
		return 0xffffff * Math.random() * 2;
	};

	getDefaultData = (): CubeRendererProps => {
		return {
			color: this.getRandomColor(),
		};
	};
}
