export class Time {
  static deltaTime: number;
  static curTime: number;
  
  static _updateTime(time: number) {
    if (Time.curTime === undefined) {
      Time.curTime = time;
    }
    this.deltaTime = time - this.curTime;
    this.curTime = time;
  }
}
