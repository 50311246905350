import {
ConnectRequest,
ConnectResponse,
  HostParams,
  HostResponse,
  JoinParams,
  JoinResponse,
  LobbyView,
} from "./Cupid/Types.ts";
import { encodeAnnouncement, encodeJoin, getUrl } from "./Cupid/Helpers.ts";

export const get = async <T>(
  path: string,
  args: Record<string, string>,
): Promise<T | null> => {
  const url = getUrl(path, args);
  return await fetch(url).then((res) => res.json()) as T | null;
};

export const asRecord = <T>(obj: T): Record<string, string> => {
  return (obj as unknown) as Record<string, string>;
};

export class Cupid {
  // get hostname of current browser page
  static HOSTNAME = "cupid.fly.dev"; //window.location.hostname;
  static URL = `https://${Cupid.HOSTNAME}`;
  static ANNOUNCE_INTERVAL = 1000;
  static JOIN_INTERVAL = 500;
  static CONNECT_INTERVAL = 1000;

  static host = async (params: HostParams): Promise<HostResponse> => {
    const getParams = encodeAnnouncement(params);
    return await get(`${Cupid.URL}/host`, asRecord(getParams));
  };

  static join = async (params: JoinParams): Promise<JoinResponse> => {
    const getParams = encodeJoin(params);
    return await get(`${Cupid.URL}/join`, asRecord(getParams));
  };

  static connect = async (params: ConnectRequest): Promise<ConnectResponse> => {
    return await get(`${Cupid.URL}/connect`, asRecord(params));
  }

  static list = async (): Promise<LobbyView[]> => {
    const response = await fetch(`${Cupid.URL}/list`);
    const json = await response.json();
    return json as LobbyView[];
  };

  static first = async (): Promise<LobbyView> => {
    const response = await fetch(`${Cupid.URL}/first`);
    const json = await response.json();
    return json as LobbyView;
  };
}
