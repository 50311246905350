// generate a uuid
export const uuidShort = () =>
  base64(crypto.getRandomValues(new Uint8Array(4)));
export const uuidLong = () =>
  base64(crypto.getRandomValues(new Uint8Array(16)));

  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
const base64 = (bytes: Uint8Array): string => {
  const len = bytes.length;
  let i = 0;
  let result = "";
  while (i < len) {
    const c1 = bytes[i++];
    const c2 = i < len ? bytes[i++] : 0;
    const c3 = i < len ? bytes[i++] : 0;
    const c4 = i < len ? bytes[i++] : 0;
    const b1 = c1 >> 2;
    const b2 = ((c1 & 3) << 4) | (c2 >> 4);
    let b3 = ((c2 & 15) << 2) | (c3 >> 6);
    let b4 = c3 & 63;
    if (isNaN(c2)) {
      b3 = b4 = 64;
    } else if (isNaN(c3)) {
      b4 = 64;
    }
    result += chars[b1] + chars[b2] + chars[b3] + chars[b4];
  }
  return result;
};

export const checkCollision = (id: string, ...otherIds: string[]) => {
  return otherIds.some((otherId) => id === otherId);
};
