import { meta } from '../Ontology/Meta.ts';
import { alloc } from './Alloc.ts';

export type Vector3Like = { x: number; y: number; z: number };

export class Vector3 implements Vector3Like {
	static get zero(): Vector3 {
		return alloc(new Vector3(0, 0, 0));
	}
	static get one(): Vector3 {
		return alloc(new Vector3(1, 1, 1));
	}
	static get up(): Vector3 {
		return alloc(new Vector3(0, 1, 0));
	}
	static get down(): Vector3 {
		return alloc(new Vector3(0, -1, 0));
	}
	static get left(): Vector3 {
		return alloc(new Vector3(-1, 0, 0));
	}
	static get right(): Vector3 {
		return alloc(new Vector3(1, 0, 0));
	}
	static get forward(): Vector3 {
		return alloc(new Vector3(0, 0, 1));
	}
	static get backward(): Vector3 {
		return alloc(new Vector3(0, 0, -1));
	}

	static staticZero = Object.freeze(Vector3.zero);
	static staticOne = Object.freeze(Vector3.one);
	static staticUp = Object.freeze(Vector3.up);
	static staticDown = Object.freeze(Vector3.down);
	static staticLeft = Object.freeze(Vector3.left);
	static staticRight = Object.freeze(Vector3.right);
	static staticForward = Object.freeze(Vector3.forward);
	static staticBackward = Object.freeze(Vector3.backward);

	x: number;
	y: number;
	z: number;

	constructor(
		x: number | Vector3Like | undefined = undefined,
		y: number | undefined = 0,
		z: number | undefined = 0,
	) {
		if (x != null && typeof x === 'object') {
			this.x = x.x;
			this.y = x.y;
			this.z = x.z;
		} else if (x != null) {
			this.x = x as number;
			this.y = y;
			this.z = z;
		} else {
			this.x = 0;
			this.y = 0;
			this.z = 0;
		}
	}

	clone(): Vector3 {
		return new Vector3(this);
	}

	set(
		x: number | Vector3Like,
		y: number | undefined = 0,
		z: number | undefined = 0,
	): Vector3 {
		if (x != null && typeof x === 'object') {
			this.x = x.x;
			this.y = x.y;
			this.z = x.z;
		} else {
			this.x = x as number;
			this.y = y!;
			this.z = z!;
		}
		return this;
	}

	static fromPool(x: number, y: number, z: number): Vector3 {
		const vec = (meta.getPool(Vector3)!).take() as unknown as Vector3;
		vec.x = x;
		vec.y = y;
		vec.z = z;
		return vec;
	}

	static add(a: Vector3Like, b: Vector3Like): Vector3Like {
		return alloc({
			x: a.x + b.x,
			y: a.y + b.y,
			z: a.z + b.z,
		});
	}

	static subtract(a: Vector3Like, b: Vector3Like): Vector3Like {
		return alloc({
			x: a.x - b.x,
			y: a.y - b.y,
			z: a.z - b.z,
		});
	}

	static dot(a: Vector3Like, b: Vector3Like): number {
		return a.x * b.x + a.y * b.y + a.z * b.z;
	}

	static cross(a: Vector3Like, b: Vector3Like): Vector3Like {
		return alloc({
			x: (a.y * b.z) - (a.z * b.y),
			y: (a.z * b.x) - (a.x * b.z),
			z: (a.x * b.y) - (a.y * b.x),
		});
	}

	static distance(a: Vector3Like, b: Vector3Like): number {
		const dx = a.x - b.x;
		const dy = a.y - b.y;
		const dz = a.z - b.z;
		return Math.sqrt(dx * dx + dy * dy + dz * dz);
	}

	static set(
		v: Vector3Like,
		x: number | Vector3Like,
		y: number | undefined = undefined,
		z: number | undefined = undefined,
	): Vector3Like {
		if (x != null && typeof x === 'number') {
			v.x = x;
			v.y = y!;
			v.z = z!;
		} else if ((x as unknown as Vector3Like).x != null) {
			const vv: Vector3Like = x as Vector3Like;
			v.x = vv.x;
			v.y = vv.y;
			v.z = vv.z;
		}
		return v;
	}

	normalize(): Vector3 {
		const norm = this.x * this.x + this.y * this.y + this.z * this.z;
		return this.set(
			this.x / norm,
			this.y / norm,
			this.z / norm,
		);
	}

	cross(v: Vector3Like): Vector3 {
		this.x = (this.y * v.z) - (this.z * v.y);
		this.y = (this.z * v.x) - (this.x * v.z);
		this.z = (this.x * v.y) - (this.y * v.x);
		return this;
	}

	dot(v: Vector3Like): number {
		return this.x * v.x + this.y * v.y + this.z * v.z;
	}

	add(v: Vector3Like): Vector3 {
		this.x += v.x;
		this.y += v.y;
		this.z += v.z;
		return this;
	}

	subtract(v: Vector3Like): Vector3 {
		this.x -= v.x;
		this.y -= v.y;
		this.z -= v.z;
		return this;
	}

	multiply(scalar: number): Vector3 {
		return this.set(
			this.x * scalar,
			this.y * scalar,
			this.z * scalar,
		);
	}

	static toXRotation(forward: Vector3Like) {
		return Math.atan2(forward.z, forward.y);
	}

	static toYRotation(forward: Vector3Like) {
		return Math.atan2(forward.z, forward.x);
	}

	static toZRotation(forward: Vector3Like) {
		return Math.atan2(forward.y, forward.x);
	}

	public getRotatedVector(v: Vector3, radians: number, target: Vector3Like) {
		target.x = v.x * Math.cos(radians) - v.z * Math.sin(radians);
		target.y = v.y;
		target.z = v.x * Math.sin(radians) + v.z * Math.cos(radians);
		return target;
	}

	public getRotatedVector_ALLOC(v: Vector3, radians: number) {
		const result = this.getRotatedVector(v, radians, alloc(new Vector3()));
		return result;
	}
}

// applyQuaternion(q: QuaternionLike): Vector3 {
// 	const x = this.x;
// 	const y = this.y;
// 	const z = this.z;
// 	const qx = q.x;
// 	const qy = q.y;
// 	const qz = q.z;
// 	const qw = q.w;

// 	//
// 	//  v' = q * v * q^-1
// 	//  q = [w, x, y, z]
// 	//  v = [x, y, z, w]

// 	// calculate quat * vector
// 	const ix = qw * x + qy * z - qz * y;
// 	const iy = qw * y + qz * x - qx * z;
// 	const iz = qw * z + qx * y - qy * x;

// 	// calculate result * inverse quat
// 	this.x = ix * qw + iy * -qz + iz * -qy - qx * -qx;
// 	this.y = iy * qw + iz * -qx + ix * -qz - qy * -qy;
// 	this.z = iz * qw + ix * -qy + iy * -qx - qz * -qz;
// 	return this;
// }
