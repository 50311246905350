import { Three } from '../../Deps/Three.ts';
import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from '../../Helpers/Quaternion.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';
import { IThreeJSObject3D } from "../API.ts";
import { qnote } from "../../Helpers/Note.ts";
import { ThreeJSHelper } from "../../Helpers/ThreeJSHelper.ts";

export type CameraData = {
	follow?: boolean;
};

export class Camera extends ThreeJSComponent<CameraData> {
	static main: Camera | null = null;
	_threeCamera: Three.PerspectiveCamera | null = null;
	_dolly: Three.Object3D | null = null;

	create = async () => {
		Camera.main = this;
		this.super.create();
		await null
	};
	
	setDolly = (dolly: IThreeJSObject3D) => {
		setTimeout(() => {
			if (this._threeCamera) {
				// dolly.getThreeObject()!.attach(this._threeCamera!);
				this._threeCamera.parent = dolly.getThreeObject()!;
				qnote('setDolly1', dolly.getThreeObject());
			}
			this._dolly = dolly.getThreeObject()!;
		}, 1000)
		// this._threeCamera!.parent?.attach();
	};
	
	update = () => {
		if (this._threeCamera == null || !this.data?.follow) {
			return;
		}
		const pos = this.entity!.transform!.position;
		const rot = this.entity!.transform!.rotation;
		this._threeCamera.position.copy(ThreeJSHelper.toVec(pos));
		this._threeCamera.quaternion.copy(ThreeJSHelper.toQuat(rot));
	};

	createThreeObject = (): Promise<Three.Object3D> => {
		this._threeCamera = new Three.PerspectiveCamera(
			75,
			window.innerWidth / window.innerHeight,
			0.01,
			1000,
		);
		// this._threeCamera.position.set(0, 0, 0);
		// this._threeCamera.po

		if (this._dolly != null) {
			// this._dolly.attach(this._threeCamera);
			this._threeCamera.parent = this._dolly;
			qnote('setDolly2', this._dolly, this._threeCamera);
		}

		return Promise.resolve(this._threeCamera);
	};
}
