import { Component } from '../../Ontology/Entity/Component.ts';
import { Time } from '../../Helpers/Time.ts';
import { Three } from '../../Deps/Three.ts';
import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from '../../Helpers/Quaternion.ts';

type SpinnerData = {
	speed?: number;
};

export class Spinner extends Component<SpinnerData> {
	_quat: Quaternion = new Quaternion();
	_threeQuat = new Three.Quaternion();
	_threeQuat2 = new Three.Quaternion();
	
	_vectorAxis = new Three.Vector3(0.131, 0.437, 1)

	_quatLike: QuaternionLike = Quaternion.get_ALLOC();

	update = () => {
		if (!this.isOwner) {
			return;
		}
		this.entity?.transform?.mutate_ALLOC((data) => {
			this._threeQuat.set(data.rotation.x, data.rotation.y, data.rotation.z, data.rotation.w).multiply(
				this._threeQuat2.setFromAxisAngle(this._vectorAxis, Time.deltaTime * this.data!.speed!)
			).normalize();

			this._quat.set(this._threeQuat);
			data.rotation = this._quat;
			return data;
		});
	};

	getDefaultData = (): SpinnerData => {
		return {
			speed: 2,
		};
	};
}
