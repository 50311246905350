import { EntityBlueprintInstance } from '../../Ontology/API.ts';
import { Entity } from '../../Ontology/Entity.ts';
import { CubeRenderer } from '../Components/CubeRenderer.ts';
import { Spinner } from '../Components/Spinner.ts';
import { ThreeJSTransform } from '../Components/ThreeJSTransform.ts';

export class NestedCube extends Entity {
	getDefaultBlueprint = (): EntityBlueprintInstance => ({
		children: [
			{
				name: 'baby-CubeRenderer',
				entity: new Entity(),
				components: [
					{
						component: new ThreeJSTransform(),
						data: {
							position: { x: 0, y: 0, z: 0 },
							rotation: { x: 0, y: 0, z: 0, w: 1 },
							scale: 1,
						},
					},
					{
						component: new CubeRenderer(),
					},
				],
			},
		],
        name: 'mama-CubeRenderer',
		components: [
			{
				component: new ThreeJSTransform(),
				data: {
					position: { x: 0, y: 1, z: 0 },
					rotation: { x: 0, y: 0, z: 0, w: 1 },
					scale: 1,
				},
			},
			{
				component: new Spinner(),
				data: {
					speed: 2,
				},
			},
		],
	});
}
