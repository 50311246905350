import { IScene } from '../Animus/API.ts';
import { AxonMessage, IAxon } from '../Axon/API.ts';
import { alloc } from '../Helpers/Alloc.ts';
import { Note } from '../Helpers/Note.ts';
import { IComponent, IEntity, IWorld } from '../Ontology/API.ts';
import {
	deserializeEntityBlueprint,
	serializeEntity,
} from '../Ontology/Helpers.ts';
import { meta } from '../Ontology/Meta.ts';
import { IOntologyAxonLink } from './API.ts';

type OntologyAxonMessageType =
	| 'oa-entity-create'
	| 'oa-entity-destroy'
	| 'oa-entity-mutate'
	| 'oa-entity-missing'
	| 'oa-entity-set-parent'
	| 'oa-component-add'
	| 'oa-component-remove'
	| 'oa-component-mutate'
	| 'oa-component-missing';

export class OntologyAxonLink implements IOntologyAxonLink {
	sendSpawnEntity:
		| ((entity: IEntity, propagator: unknown | undefined) => void)
		| undefined;
	sendMutateEntity:
		| ((entity: IEntity, propagator: unknown | undefined) => void)
		| undefined;
	sendDestroyEntity:
		| ((entity: IEntity, propagator: unknown | undefined) => void)
		| undefined;
	sendSetParent:
		| ((
			entity: IEntity,
			parent: IEntity,
			propagator: unknown | undefined,
		) => void)
		| undefined;

	receiveSpawnEntity: ((message: AxonMessage) => void) | undefined;
	receiveMutateEntity: ((message: AxonMessage) => void) | undefined;
	receiveDestroyEntity: ((message: AxonMessage) => void) | undefined;
	receiveMissingEntity: ((message: AxonMessage) => void) | undefined;
	receiveSetParent: ((message: AxonMessage) => void) | undefined;

	sendAddComponent:
		| ((
			entity: IEntity,
			component: IComponent<any>,
			propagator: unknown | undefined,
		) => void)
		| undefined;
	sendMutateComponent:
		| ((
			entity: IEntity,
			component: IComponent<any>,
			data: any,
			propagator: unknown | undefined,
		) => void)
		| undefined;
	sendRemoveComponent:
		| ((
			entity: IEntity,
			component: IComponent<any>,
			propagator: unknown | undefined,
		) => void)
		| undefined;

	receiveAddComponent: ((message: AxonMessage) => void) | undefined;
	receiveMutateComponent: ((message: AxonMessage) => void) | undefined;
	receiveRemoveComponent: ((message: AxonMessage) => void) | undefined;
	receiveMissingComponent: ((message: AxonMessage) => void) | undefined;

	link = (world: IWorld, scene: IScene, axon: IAxon) => {
		// Handle world spawn / destroy
		this.sendAddComponent = (entity, component, propagator) => {
			if (
				entity.privacy === 'private' ||
				component.privacy === 'private' ||
				propagator == this ||
				axon.peer.status !== 'connected'
			) {
				return;
			}
			axon.send(alloc({
				type: 'oa-component-add',
				payload: {
					entityId: entity.id,
					component: component.constructor.name,
					privacy: component.privacy,
					data: component.data,
				},
			}));
		};

		this.sendMutateComponent = (entity, component, data, propagator) => {
			if (
				entity.privacy === 'private' ||
				component.privacy === 'private' ||
				propagator == this ||
				axon.peer.status !== 'connected'
			) {
				return;
			}
			axon.send(alloc({
				type: 'oa-component-mutate',
				payload: {
					entityId: entity.id,
					component: component.constructor.name,
					data,
				},
			}));
		};

		this.sendRemoveComponent = (entity, component, propagator) => {
			if (
				entity.privacy === 'private' ||
				component.privacy === 'private' ||
				propagator == this ||
				axon.peer.status !== 'connected'
			) {
				return;
			}
			axon.send(alloc({
				type: 'oa-component-remove',
				payload: {
					entityId: entity.id,
					component: component.constructor.name,
				},
			}));
		};

		this.sendSpawnEntity = (entity, propagator) => {
			if (
				entity.privacy === 'private' || propagator == this ||
				axon.peer.status !== 'connected'
			) {
				return;
			}
			Note.info('ontology-axon', 'Sending entity', entity.id);
			axon.send(alloc({
				type: 'oa-entity-create',
				payload: serializeEntity(entity),
			}));
		};

		this.sendDestroyEntity = (entity, propagator) => {
			if (
				entity.privacy === 'private' || propagator == this ||
				axon.peer.status !== 'connected'
			) {
				return;
			}
			axon.send(alloc({
				type: 'oa-entity-destroy',
				payload: entity.id,
			}));
		};

		this.sendSetParent = (entity, parent, propagator) => {
			if (
				entity.privacy === 'private' || propagator == this ||
				axon.peer.status !== 'connected'
			) {
				return;
			}
			axon.send(alloc({
				type: 'oa-entity-set-parent',
				payload: {
					entityId: entity.id,
					parentId: parent?.id,
				},
			}));
		};

		world.subscribe('entity-create', this.sendSpawnEntity);
		world.subscribe('entity-destroy', this.sendDestroyEntity);
		world.subscribe('entity-set-parent', this.sendSetParent);
		world.subscribe('component-create', this.sendAddComponent);
		world.subscribe('component-destroy', this.sendRemoveComponent);
		world.subscribe('component-mutate', this.sendMutateComponent);

		// ------------------------------------------------------------------------------
		// ------------------------------------------------------------------------------
		// ------------------------------------------------------------------------------

		// Handle axon spawn / destroy
		this.receiveSpawnEntity = (message) => {
			if (message.type !== 'oa-entity-create') {
				return;
			}
			const serializedBlueprint = message.payload as any;
			const blueprint = deserializeEntityBlueprint(
				serializedBlueprint,
				world,
			);

			if (!blueprint) {
				Note.warn('ontology-axon', `Unknown entity type: ${name}`);
				return;
			}

			if (world.entities.find((e) => e.id === blueprint.id)) {
				Note.warn(
					'ontology-axon',
					`Entity already exists: ${blueprint.id}`,
				);
				return;
			}

			Note.info('ontology-axon', 'Receiving entity', blueprint.id);

			const entity = blueprint.entity!;
			entity.isOwner = false;
			entity.parent = world.get(serializedBlueprint.parentId);
			world.spawn(entity, scene, blueprint, this);
		};

		this.receiveDestroyEntity = (message) => {
			if (message.type !== 'oa-entity-destroy') {
				return;
			}
			const id = message.payload as any;
			const entity = world.entities.find((e) => e.id === id);
			if (entity) {
				entity.destroy(this);
			} else {
				Note.warn(
					'ontology-axon',
					`Received destroy for unknown entity: ${id}`,
				);
			}
		};

		this.receiveAddComponent = (message) => {
			if (message.type !== 'oa-component-add') {
				return;
			}
			const payload = message.payload as any;
			const entity = world.entities.find((e) =>
				e.id === payload.entityId
			);
			if (entity) {
				const component = meta.getClass(payload.component)!;
				if (component) {
					const componentInstance = entity.addComponent(
						component,
						payload.blueprint,
						this,
					);
					componentInstance.isOwner = false;
				} else {
					Note.error(
						'ontology-axon',
						`Component ${payload.component} not found`,
					);
				}
			} else {
				Note.warn(
					'ontology-axon',
					`Entity ${payload.entityId} not found (add component ${payload.component})`,
				);
			}
		};

		this.receiveMutateComponent = (message) => {
			if (message.type !== 'oa-component-mutate') {
				return;
			}
			const payload = message.payload as any;
			const entity = world.entities.find((e) =>
				e.id === payload.entityId
			);
			if (entity) {
				const component = entity.getComponent(payload.component);
				if (component) {
					component.data = payload.data;
				} else {
					Note.warn(
						'ontology-axon',
						`Component ${payload.component} not found on entity ${payload.entityId} (mutate)`,
					);
					axon.send({
						type: 'oa-component-missing',
						payload: {
							entityId: entity.id,
							component: payload.component,
						},
					});
				}
			} else {
				Note.warn(
					'ontology-axon',
					`Entity ${payload.entityId} not found (mutate)`,
				);
				axon.send({
					type: 'oa-entity-missing',
					payload: {
						entityId: payload.entityId,
					},
				});
			}
		};

		this.receiveRemoveComponent = (message) => {
			if (message.type !== 'oa-component-remove') {
				return;
			}
			const payload = message.payload as any;
			const entity = world.entities.find((e) =>
				e.id === payload.entityId
			);
			if (entity) {
				const component = entity.removeComponent(
					payload.component,
					this,
				);
				if (component) {
					// nothing
				} else {
					Note.warn(
						'ontology-axon',
						`Component ${payload.component} not found on entity ${payload.entityId} (remove)`,
					);
					// ignore since we didn't have anyway
				}
			} else {
				Note.warn(
					'ontology-axon',
					`Entity ${payload.entityId} not found (remove)`,
				);
			}
		};

		this.receiveMissingEntity = (message) => {
			if (message.type !== 'oa-entity-missing') {
				return;
			}
			const payload = message.payload as any;
			Note.warn(
				'ontology-axon',
				'Received missing entity',
				payload.entityId,
			);
			const entity = world.entities.find((e) =>
				e.id === payload.entityId
			);
			if (entity) {
				this.sendSpawnEntity!(entity, null);
			} else {
				Note.warn(
					'ontology-axon',
					`Entity ${payload.entityId} not found (missing)`,
				);
			}
		};

		this.receiveMissingComponent = (message) => {
			if (message.type !== 'oa-component-missing') {
				return;
			}
			const payload = message.payload as any;
			const entity = world.entities.find((e) =>
				e.id === payload.entityId
			);
			Note.warn(
				'ontology-axon',
				'Received missing component',
				payload.entityId,
				payload.component,
			);
			if (entity) {
				const component = entity.getComponent(payload.component);
				if (component) {
					this.sendAddComponent!(entity, component, null);
				} else {
					Note.warn(
						'ontology-axon',
						`Component ${payload.component} not found on entity ${payload.entityId} (missing)`,
					);
				}
			} else {
				Note.warn(
					'ontology-axon',
					`Entity ${payload.entityId} not found (missing)`,
				);
			}
		};

		this.receiveSetParent = (message) => {
			if (message.type !== 'oa-entity-set-parent') {
				return;
			}
			const payload = message.payload as any;
			const entity = world.entities.find((e) =>
				e.id === payload.entityId
			);
			if (entity) {
				const parent = payload.parentId &&
					world.entities.find((e) => e.id === payload.parentId);
				if (parent) {
					entity.setParent(parent);
				}
			} else {
				Note.warn(
					'ontology-axon',
					`Entity ${payload.entityId} not found (set parent)`,
				);
			}
		};

		axon.subscribe('receive', this.receiveSpawnEntity);
		axon.subscribe('receive', this.receiveDestroyEntity);
		axon.subscribe('receive', this.receiveMissingEntity);
		axon.subscribe('receive', this.receiveSetParent);

		axon.subscribe('receive', this.receiveAddComponent);
		axon.subscribe('receive', this.receiveMutateComponent);
		axon.subscribe('receive', this.receiveRemoveComponent);
		axon.subscribe('receive', this.receiveMissingComponent);

		axon.subscribe('connected', () => {
			world.entities.forEach((entity) => {
				this.sendSpawnEntity!(entity, null);
			});
		});

		axon.subscribe('disconnected', () => {
			world.entities.forEach((entity) => {
				if (!entity.isOwner) {
					entity.destroy(this);
				}
			});
		});
	};

	unlink = (world: IWorld, _scene: IScene, axon: IAxon) => {
		world.unsubscribe('entity-create', this.sendSpawnEntity!);
		world.unsubscribe('entity-destroy', this.sendDestroyEntity!);
		world.unsubscribe('entity-set-parent', this.sendSetParent!);
		world.unsubscribe('component-create', this.sendAddComponent!);
		world.unsubscribe('component-destroy', this.sendRemoveComponent!);
		world.unsubscribe('component-mutate', this.sendMutateComponent!);

		axon.unsubscribe('receive', this.receiveAddComponent!);
		axon.unsubscribe('receive', this.receiveMutateComponent!);
		axon.unsubscribe('receive', this.receiveRemoveComponent!);
		axon.unsubscribe('receive', this.receiveMissingComponent!);
		axon.unsubscribe('receive', this.receiveSpawnEntity!);
		axon.unsubscribe('receive', this.receiveDestroyEntity!);
		axon.unsubscribe('receive', this.receiveSetParent!);
	};
}
