import { Gaia } from './Core/Gaia/mod.ts';
import { Vector3, Vector3Like } from './Core/Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from './Core/Helpers/Quaternion.ts';
import type { IWorld } from './Core/Ontology/API.ts';
import { World } from './Core/Ontology/World.ts';
import { Axon } from './Core/Axon/mod.ts';
import { IOntologyAxonLink } from './Core/OntologyAxon/API.ts';
import { OntologyAxonLink } from './Core/OntologyAxon/mod.ts';
import { Player, PlayerXRComponent } from './Core/Animus/Entities/Player.ts';
import { init as appInit } from './AppMeta.ts';
import { ThreeJSScene } from './Core/Animus/Scene/ThreeJSScene.ts';
import { Entity, UpdatingEntity } from './Core/Ontology/Entity.ts';
import { Light } from './Core/Animus/Components/Light.ts';
import { XRFrame, XRSession } from './Core/Deps/WebXR.ts';
import { XR } from './Core/Helpers/XR.ts';
import { Time } from './Core/Helpers/Time.ts';
import { Aer } from './Core/Aer/Aer.ts';
import { GLTFMesh } from './Core/Animus/Components/GLTFMesh.ts';
import { Camera } from './Core/Animus/Components/Camera.ts';
import { OrbitControls } from './Core/Animus/Components/OrbitControls.ts';
import { qnote } from './Core/Helpers/Note.ts';
import { waitUntil } from './Core/Helpers/Wait.ts';
import { CubeRenderer } from './Core/Animus/Components/CubeRenderer.ts';
import { Spinner } from './Core/Animus/Components/Spinner.ts';
import { PlayerControls } from './Core/Animus/Components/PlayerControls.ts';
import { Input } from './Core/Helpers/Input.ts';
import { HypercubeRenderer } from './Core/Animus/Components/HypercubeRenderer.ts';
import { Empty } from './Core/Animus/Components/Empty.ts';
import { ThreeJSTransform } from './Core/Animus/Components/ThreeJSTransform.ts';
import { Stats2D } from './Core/Animus/Components/Stats2D.ts';
import { Stats3D } from './Core/Animus/Components/Stats3D.ts';

class App {
	aer: Aer;
	scene: ThreeJSScene;
	world: IWorld;
	gaia: Gaia;
	ontologyAxonLink: IOntologyAxonLink;
	xrSession: XRSession | null = null;

	constructor() {
		appInit();

		this.scene = new ThreeJSScene();
		this.world = new World();
		this.gaia = new Gaia(this.world, this.scene, Axon);
		this.aer = new Aer();

		this.aer.link(Axon);
		this.ontologyAxonLink = new OntologyAxonLink();
		this.ontologyAxonLink.link(this.world, this.scene, Axon);

		// @ts-ignore - this is an additional developer tool!
		window.gaia = window.g = window.dev = this.gaia;
		// @ts-ignore - k
		this.gaia.app = this;
	}

	createScene = async () => {
		this.gaia.spawn2(Entity, 'private', [{
			component: new ThreeJSTransform(),
			data: {
				position: Vector3.zero,
				rotation: Quaternion.identity,
				scale: Vector3.one,
			},
		}, {
			component: new GLTFMesh(),
			data: {
				url: 'assets/terrain_flat.glb',
			},
		}]);

		this.gaia.spawn2(Entity, 'private', [{
			component: new ThreeJSTransform(),
			data: {
				position: Vector3.zero,
				rotation: Quaternion.identity,
				scale: Vector3.one.multiply(2),
			},
		}, {
			component: new GLTFMesh(),
			data: {
				url: 'assets/skysphere.glb',
				shadowsEnabled: false,
			},
		}]);

		Player.Local = new Player();
		const player = this.world.spawn(
			Player.Local,
			this.scene,
			null,
			this,
		);

		player.addComponent(PlayerControls, { privacy: 'private' });

		const playerHead = player.getChildByName('player-head')!;

		const xrOn = await XR.isEnabled();
		if (xrOn) {
			const camera = playerHead.addComponent(Camera, {
				privacy: 'private',
				data: { follow: false },
			}, null);
			camera!.setDolly(player.getComponent(Empty)!);
		} else {
			playerHead.addComponent(Camera, {
				privacy: 'private',
				data: { follow: false },
			}, null);
		}

		this.gaia.spawn2(Entity, 'private', [{
			component: new ThreeJSTransform(),
			data: {
				position: { x: 5, y: 20, z: 5 },
			},
		}, {
			component: new Light(),
			data: {
				type: 'point',
				color: 0x87ceeb,
				intensity: 0.9,
			},
		}]);

		// this.gaia.spawn2(UpdatingEntity, 'private', [{
		// 	component: new ThreeJSTransform(),
		// 	data: {
		// 		position: { x: 0, y: 1, z: -5 },
		// 	}
		// }, {
		// 	component: new HyperCubeRenderer(),
		// }])

		this.gaia.spawn2(Entity, 'private', [{
			component: new ThreeJSTransform(),
			data: {
				position: { x: 2, y: 10, z: 2 },
			},
		}, {
			component: new Light(),
			data: {
				type: 'ambient',
				color: 0xffffff0,
				intensity: 1,
			},
		}]);

		// this.spawnZeCubeRenderers(5);
		XR.isEnabled().then((xrOn) => {
			if (xrOn) {
				this.spawnGuideCubes();
			}
		});
	};

	start = async () => {
		await this.createScene();

		// @ts-ignore this works
		this.scene._renderer.setAnimationLoop(this.tick);

		Axon.connect();

		const getPlayerXREnabled = () =>
			Player.Remote?.getComponent(PlayerXRComponent)?.data?.isInXR;

		Axon.subscribe('connected', (isHost: boolean) => {
			waitUntil(() => getPlayerXREnabled() != null, () => {
				qnote('other player is in XR? ', getPlayerXREnabled());
				if (
					!isHost || getPlayerXREnabled() === true
				) {
					// Camera.main!.getThreeObject()!.position.z = -1.5;
					Player.Local!.transform!.position = {
						x: 0,
						y: 0,
						z: -1.5,
					};
					Player.Local!.transform!.rotation = new Quaternion()
						.rotateY(Math.PI);
					Player.Local!.getComponent(PlayerControls)
						?._hackFlipAround();
				}

				if (isHost) {
					// this.spawnZeCubeRenderers(10);
				}
			});
		});
	};

	spawnGuideCubes = () => {
		// Spawn two cubes, one in front and higher than the other
		// So the VR player can see where is forward!
		const cube1 = this.gaia.spawn2(Entity, 'private', [{
			component: new ThreeJSTransform(),
			data: {
				position: { x: 0, y: 0, z: -1 },
			},
		}, {
			component: new CubeRenderer(),
		}]);

		const cube2 = this.gaia.spawn2(Entity, 'private', [{
			component: new ThreeJSTransform(),
			data: {
				position: { x: 0, y: 0.5, z: 0 },
			},
		}, {
			component: new CubeRenderer(),
		}]);
	};

	spawnZeCubeRenderers = (quantity: number) => {
		for (let i = 0; i < quantity; i++) {
			this.gaia.spawn2(UpdatingEntity, undefined, [{
				component: new ThreeJSTransform(),
			}, {
				component: new CubeRenderer(),
			}, {
				component: new Spinner(),
			}]);
		}
	};

	tick = (time: number, frame?: XRFrame) => {
		Input._update();
		Time._updateTime(time / 1000);
		XR.updateXRFrame(frame || null);
		this.world.tick();
		this.scene.render();
	};

	destroy = () => {
		this.ontologyAxonLink.unlink(this.world, this.scene, Axon);
	};
}

window.onload = async () => {
	const app = new App();
	await app.start();
};
