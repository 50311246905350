import { GLTFLoader, ThreeStats } from '../../Deps/ThreeJSM.ts';
import { Three } from '../../Deps/Three.ts';
import { IThreeJSObject3D } from '../API.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';
import { Component } from '../../Ontology/Entity/Component.ts';
export type StatsData = {};

export class Stats2D extends Component<StatsData> {
    threeStats: ReturnType<typeof ThreeStats> | null = null;
    
    create = () => {
        this.threeStats = ThreeStats();
        document.body.appendChild(this.threeStats.dom)
        this.threeStats.dom.style.position = 'absolute';
        this.threeStats.dom.style.left = '160px';
        this.threeStats.dom.style.top = '30px';
    }

    update = () => {
        this.threeStats!.update();
    }
}