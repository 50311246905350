// TODO ENSURE THESE ARE CLEANED UP
const POLL_FREQUENCY_MS = 100;
const MAX_ATTEMPTS = 50;

export const waitUntilX = async (
    condition,
    callback: Function | undefined = undefined,
  ): Promise<void> => {
    let attempts = 0;
    // wait ms
    while (!condition() && attempts < MAX_ATTEMPTS) {
      await wait(POLL_FREQUENCY_MS);
      attempts++;
    }
    if (attempts >= MAX_ATTEMPTS) {
        return await Promise.reject(new Error("Condition not met"));
    }
    callback && callback();
    return await Promise.resolve();
  };

export const waitUntil = async (
  condition,
  callback: Function | undefined = undefined,
): Promise<void> => {
  let attempts = 0;
  // wait ms
  while (!condition() && attempts < MAX_ATTEMPTS) {
    await wait(POLL_FREQUENCY_MS);
    attempts++;
  }
  if (attempts >= MAX_ATTEMPTS) {
      return await Promise.resolve();
  }
  callback && callback();
  return await Promise.resolve();
};

export const wait = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

