import {
  DefaultObjectPoolHelper,
  IObjectPool,
  LazyObjectPool,
} from "../Helpers/Pool.ts";
import { Constructor } from "../Helpers/Types.ts";
import { IMeta } from "./API.ts";

class Meta implements IMeta {
  classToString: Map<Constructor<unknown>, string> = new Map();
  stringToClass: Map<string, Constructor<unknown>> = new Map();

  classToPool: Map<Constructor<unknown>, IObjectPool<unknown>> = new Map();

  registerClass = <T>(
    cls: Constructor<T>,
    objectPool: IObjectPool<T> | undefined = undefined,
  ) => {
    const name = cls.name;
    this.classToString.set(cls, name);
    this.stringToClass.set(name, cls);

    this.classToPool.set(
      cls,
      objectPool ?? new LazyObjectPool(new DefaultObjectPoolHelper(cls)),
    );
  };

  getClass = (name: string): Constructor<any> | undefined => {
    const cls = this.stringToClass.get(name);
    if (cls == null) {
      console.error(
        `Class ${name} not found!\nMake sure to call meta.registerClass(${name}) earlier on.`,
      );
    }
    return cls;
  };

  getPool = (cls: Constructor<unknown>): IObjectPool<unknown> | undefined => {
    const pool = this.classToPool.get(cls);
    if (pool == null) {
      console.error(
        `Pool for class ${cls.name} not found!\nMake sure to call meta.registerClass(${cls.name}) earlier on.`,
      );
    }
    return pool;
  };
}

const meta = new Meta();
// @ts-expect-error I want this
window.meta = meta;
export { meta };
