export type Observer = (...args: any[]) => void;

export type IObservable<T> = {
  subscribe(event: T, observer: Observer): void;
  unsubscribe(event: T, observer: Observer): void;
  unsubscribeAll(event?: T): void;
  emit(event: T, ...args: any[]): void;
};

export class Observable<T> implements IObservable<T> {
  observers: Map<T, Observer[]> = new Map();

  subscribe(event: T, observer: Observer) {
    if (!this.observers.has(event)) {
      this.observers.set(event, []);
    }
    this.observers.get(event)?.push(observer);
  }

  unsubscribe(event: T, observer: Observer) {
    if (this.observers.has(event)) {
      const observers = this.observers.get(event);
      observers?.splice(observers.indexOf(observer), 1);
    }
  }

  unsubscribeAll(event?: T) {
    if (event) {
      this.observers.delete(event);
    } else {
      this.observers.clear();
    }
  }

  emit(event: T, ...args: any[]) {
    if (this.observers.has(event)) {
      const observers = this.observers.get(event);
      if (!observers) {
        return;
      }
      for (let i = 0; i < observers.length; i++) {
        observers[i](...args);
      }
    }
  }
}
