import {
  GLTFLoader,
} from "../../Deps/ThreeJSM.ts";
import { Three } from "../../Deps/Three.ts";
import { IThreeJSObject3D } from "../API.ts";
import { ThreeJSComponent } from "./ThreeJSComponent.ts";

export type GLTFMeshData = {
  url: string;
  shadowsEnabled?: boolean;
  hide?: boolean,
};

export class GLTFMesh extends ThreeJSComponent<GLTFMeshData>
  implements IThreeJSObject3D {
  createThreeObject = async (): Promise<Three.Object3D> => {
    const loader = new GLTFLoader();
    const gltf = await loader.loadAsync(this.data!.url);
    const mesh = gltf.scene as Three.Object3D

    mesh.castShadow = this.data!.shadowsEnabled ?? true;
    mesh.receiveShadow = this.data!.shadowsEnabled ?? true;

    return mesh;
  };

  getDefaultData = (): GLTFMeshData => ({
    url: "./assets/fox_flask.glb",
  });
}
