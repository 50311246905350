import { Three } from "../../Deps/Three.ts";
import { Note } from "../../Helpers/Note.ts";
import { Empty } from "../../Helpers/Types.ts";
import { ThreeJSComponent } from "./ThreeJSComponent.ts";

export class AudioListener extends ThreeJSComponent<Empty> {
  static Instance: AudioListener | undefined;

  constructor() {
    super();
    if (AudioListener.Instance) {
      Note.error(
        "_",
        "AudioListener is a singleton and can only be instantiated once.",
      );
    }
    AudioListener.Instance = this;
  }

  createThreeObject = () => {
    return Promise.resolve(new Three.AudioListener());
  };
}
