import { Entity, UpdatingEntity } from '../../Ontology/Entity.ts';
import { AudioListener } from '../Components/AudioListener.ts';
import { XRFollower } from '../Components/XRFollower.ts';
import { CubeRenderer } from '../Components/CubeRenderer.ts';
import { GLTFMesh } from '../Components/GLTFMesh.ts';
import { Component } from '../../Ontology/Entity/Component.ts';
import { XR } from '../../Helpers/XR.ts';
import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from '../../Helpers/Quaternion.ts';
import { qnote } from '../../Helpers/Note.ts';
import { Spinner } from '../Components/Spinner.ts';
import { Empty } from '../Components/Empty.ts';
import { ThreeJSComponent } from '../Components/ThreeJSComponent.ts';
import { ThreeJSTransform } from '../Components/ThreeJSTransform.ts';
import { Follower } from '../Components/Follower.ts';
import { Stats3D } from '../Components/Stats3D.ts';
import { Debug } from '../../Helpers/Debug.ts';
import { PlayerHand } from "../Components/PlayerHand.ts";

type PlayerXRData = {
	isInXR: boolean | null;
};

export class PlayerXRComponent extends Component<PlayerXRData> {}

export class Player extends Entity {
	static Local: Player | undefined;
	static Remote: Player | undefined;

	createOverridable = () => {
		if (this === Player.Local) {
			// Hide face
			this.asyncLocalInit();
		} else {
			Player.Remote = this;
		}
	};

	asyncLocalInit = async () => {
		const head = this.getChildByName('player-head')!;
		head.getComponent(ThreeJSComponent)!.visible = false;
		// head.addComponent(AudioListener, { privacy: 'private' });

		// const torso = this.getChildByName('player-torso')!;
		// torso.getComponent(Follower)?.setTarget(head.transform!);

		const isXRPlayer = await XR.isEnabled();

		this.getComponent(PlayerXRComponent)!.mutate_ALLOC(() => ({
			isInXR: isXRPlayer,
		}));

		if (!isXRPlayer && this == Player.Local) {
			// qnote('destroying local hands...');
			// this.getChildByName('player-left-hand')!.destroy(null);
			// this.getChildByName('player-right-hand')!.destroy(null);
		}

		// this.getComponent	
	};

	getDefaultBlueprint = () => {
		return {
			name: 'player',
			children: [
				{
					name: 'player-head',
					entity: new Entity(),
					components: [
						{
							component: new ThreeJSTransform(),
							data: {
								position: new Vector3(0, 1, 0),
								rotation: Quaternion.identity,
								scale: Vector3.one,
							},
						},
						{
							component: new XRFollower(),
							privacy: 'private' as const,
						},
						{
							component: new GLTFMesh(),
							data: {
								url: './assets/player-head.glb',
								flipZ: true,
							},
						},
					],
				},
				// {
				// 	name: 'player-torso',
				// 	entity: new Entity(),
				// 	components: [
				// 		{
				// 			component: new ThreeJSTransform(),
				// 			data: {
				// 				position: new Vector3(0, 1, 0),
				// 				rotation: Quaternion.identity,
				// 				scale: Vector3.one,
				// 			},
				// 		},
				// 		{
				// 			component: new GLTFMesh(),
				// 			data: {
				// 				url: './assets/player-torso.glb',
				// 				flipZ: true,
				// 			},
				// 		},
				// 		// {
				// 		// 	component: new Follower(),
				// 		// 	data: {
				// 		// 		offsetPosition: new Vector3(0, -0.05, 0),
				// 		// 		offsetRotation: new Vector3(0, Math.PI, 0),
				// 		// 		followRotation: 'y',
				// 		// 	}
				// 		// }
				// 	],
				// },
				{
					name: 'player-left-hand',
					entity: new Entity(),
					components: [
						{
							component: new ThreeJSTransform(),
							data: {
								position: {
									x: -0.25,
									// y: 0,
									y: 0.5,
									z: -0.1,
								},
								rotation: Quaternion.identity,
								scale: Vector3.one.multiply(0.82),
							},
						},
						{
							component: new XRFollower(),
							privacy: 'private' as const,
							data: {
								follow: 'left-hand',
							},
						},
						{
							component: new GLTFMesh(),
							data: {
								url: './assets/player-left-hand.glb',
								offsetRotateEuler: {
									x: 0,
									y: Math.PI * 1.5,
									z: -Math.PI * 0.25,
								},
								flipZ: true,
							},
						},
						{
							component: new PlayerHand(),
							data: {
								hand: 'left'
							}
						}
					],
				},
				{
					name: 'player-right-hand',
					entity: new Entity(),
					components: [
						{
							component: new ThreeJSTransform(),
							data: {
								position: {
									x: 0.25,
									// y: 0,
									y: 0.5,
									z: -0.1,
								},
								rotation: Quaternion.identity,
								scale: Vector3.one.multiply(0.82),
							},
						},
						{
							component: new XRFollower(),
							privacy: 'private' as const,
							data: {
								follow: 'right-hand',
							},
						},
						{
							component: new GLTFMesh(),
							data: {
								url: './assets/player-right-hand.glb',
								offsetRotateEuler: {
									x: 0,
									y: -Math.PI / 2,
									z: -Math.PI / 4,
								},
								flipZ: true,
							},
						},
						{
							component: new PlayerHand(),
							data: {
								hand: 'right'
							}
						}
					],
				},
				{
					name: 'player-ui',
					privacy: 'private' as const,
					components: [
						{
							component: new ThreeJSTransform(),
							data: {
								position: new Vector3(0, 0.5, -1),
							},
						},
						...(Debug.enabled
							? [{
								component: new Stats3D(),
							}]
							: []),
					],
				},
			],
			components: [
				{
					component: new ThreeJSTransform(),
					data: {
						position: Vector3.zero,
						rotation: Quaternion.identity,
						scale: Vector3.one,
					},
				},
				{
					component: new PlayerXRComponent(),
					data: {
						isInXR: null,
					},
				},
				{
					// dolly target for VR camera
					component: new Empty(),
				},
			],
		};
	};
}
