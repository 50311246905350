import { Component } from "../../Ontology/Entity/Component.ts";

type AudioSourceData = {
    sound: string,
    volume: number,
    loop: boolean,
}

export class AudioSource extends Component<AudioSourceData> {
    getDefaultData = () => {
        return {
            sound: "",
            volume: 1,
            loop: false,
        };
    }
}