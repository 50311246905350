import { IAxon, WebRTCPeer } from "../Axon/API.ts";

const constraints = {
  audio: true,
  // video: false,
};

export class Aer {
  axon: IAxon | null = null;

  link = async (axon: IAxon): Promise<void> => {
    this.axon = axon;
    // await this.startReceiving(this.axon!.peer, this.axon!.peer.conn!);
    // await this.startStreaming(this.axon!.peer, this.axon!.peer.conn!);
    // this.axon.onConnected.push(() => this.startStreaming);
    this.axon!.peer.subscribe("new-peer", async () => {
      // await this.startReceiving(this.axon!.peer, this.axon!.peer.conn!);
      // await this.startStreaming(this.axon!.peer, this.axon!.peer.conn!);
    });
    this.axon!.peer.subscribe("connected", async () => {
      // this.axon!.peer!.conn.media
    });
  };


  startStreaming = async (peer: WebRTCPeer, conn: RTCPeerConnection) => {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    for (const track of stream.getTracks()) {
      console.log("adding local track", track, stream);
      conn.addTrack(track, stream);
    }
    peer.audioInitialized = true
  };

  startReceiving = (peer: WebRTCPeer, conn: RTCPeerConnection) => {
    console.log("start receiving");
    // conn.ontrack = this.onTrack
    // conn.removeEventListener('track', this.onTrack);

    // why isn't this being called???
    // try something else:
    // conn.addEventListener("track", this.onTrack)
  };

  onTrack = (event: RTCTrackEvent) => {
    console.log("REMOTE TRACK IS HERE HUZZZZAAAAH", event.track);
    const audio = document.getElementById("remote-audio");
    const stream = new MediaStream();
    (audio! as any).srcObject = stream;
    stream.addTrack(event.track);
  }
}
