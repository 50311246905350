import { Three } from '../../Deps/Three.ts';
import { Component } from '../../Ontology/Entity/Component.ts';
import { IThreeJSObject3D } from '../API.ts';
import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from '../../Helpers/Quaternion.ts';
import { qnote } from '../../Helpers/Note.ts';
import { ThreeJSTransform } from './ThreeJSTransform.ts';
import { ITransform, ITransformData } from '../../Ontology/API.ts';

export type ThreeJSObjectData = {
	hide?: boolean;
	offsetRotateEuler?: Vector3Like;
	flipZ?: boolean;
};

const pendingPromise = Promise.race.bind(Promise, []);

export class ThreeJSComponent<T> extends Component<T & ThreeJSObjectData>
	implements IThreeJSObject3D {
	_threeObject: Three.Object3D | null = null;
	_q: Quaternion = new Quaternion();
	_tq: Three.Quaternion = new Three.Quaternion();

	_cancelCreatePromise: (() => void) | undefined = undefined;

	visible = true;
	updateSelf = true;

	constructor() {
		super();
		// TODO: wtf clean this up
		this.super = { duper: this.super, ...this };
	}

	getThreeObject = (): Three.Object3D | null => {
		return this._threeObject;
	};

	createThreeObject = (): Promise<Three.Object3D | null> => {
		throw new Error(
			`Please implement createThreeObject() on ${this.constructor.name}`,
		);
	};

	mutateOverride = (data: T & ThreeJSObjectData) => {
		return data;
	};

	interruption = (): Promise<null> => {
		return new Promise((resolve) => {
			this._cancelCreatePromise = function () {
				resolve(null);
			};
		});
	};

	create = async () => {
		const threeObject = await Promise.race([
			this.createThreeObject(),
			this.interruption(),
		]);
		if (threeObject == null) {
			// interrupted
			qnote('interrupted');
			return;
		}
		// qnote(
		// 	'ThreeJSComponent.create',
		// 	this.constructor.name,
		// 	this.state,
		// 	this.data,
		// );

		if (this.state === 'dead') {
			return;
		}
		this._threeObject = threeObject!;
		this.entity!.scene!.add(this);
	};

	destroy = () => {
		qnote('destroying', this.constructor.name, this._threeObject);
		this._cancelCreatePromise && this._cancelCreatePromise();
		this._cancelCreatePromise = undefined;
		if (this._threeObject) {
			qnote('destroying', this.constructor.name, 'threeObject');
			this.entity!.scene!.remove(this);
			this._threeObject = null;
		}
		(this.super as any).duper.destroy();
	};

	update = () => {
		if (!this._threeObject) {
			return;
		}
		if (!this.updateSelf) {
			return;
		}
		const data = this.data!
		if (data.hide) {
			this.visible = false;
		}
		this._q.set(Quaternion.staticIdentity)
		if (data.offsetRotateEuler) {
			this._q.rotateX(data.offsetRotateEuler.x);
			this._q.rotateY(data.offsetRotateEuler.y);
			this._q.rotateZ(data.offsetRotateEuler.z);
		}
		if (data.flipZ) {
			this._q.rotateY(Math.PI);
		}
		this._threeObject!.quaternion.set(
			this._q.x,
			this._q.y,
			this._q.z,
			this._q.w,
		);
		return;

		// Everything is set by the underlying group!

		// const { position, rotation, scale } = this.transform.getWorld()!;
		// this._threeObject.position.set(position.x, position.y, position.z);
		// this._q.set(rotation.x, rotation.y, rotation.z, rotation.w);
		// if (this.data?.flipZ) {
		// 	this._q.rotateY(
		// 		Math.PI,
		// 	);
		// }
		// if (this.data?.offsetRotateEuler) {
		// 	const offsetRotateEuler = this.data.offsetRotateEuler;
		// 	this._q.rotateX(offsetRotateEuler.x);
		// 	this._q.rotateY(offsetRotateEuler.y);
		// 	this._q.rotateZ(offsetRotateEuler.z);
		// }
		// this._tq.set(this._q.x, this._q.y, this._q.z, this._q.w);
		// this._threeObject.rotation.setFromQuaternion(this._tq);

		// const numberScale = typeof scale === 'number' ? scale as number : null;
		// const vectorScale = numberScale == null
		// 	? scale as unknown as (Vector3Like | null)
		// 	: null;
		// this._threeObject!.scale.set(
		// 	numberScale ?? vectorScale?.x ?? 1,
		// 	numberScale ?? vectorScale?.y ?? 1,
		// 	numberScale ?? vectorScale?.z ?? 1,
		// );
	};

	get transform() {
		return this.entity!.getComponent(
			ThreeJSTransform,
		) as unknown as ITransform<ITransformData>;
	}
}
