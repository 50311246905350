import { IObject3D, IScene, IThreeJSObject3D } from "../API.ts";
import { Three } from "../../Deps/Three.ts";
import { XR, XRButton } from "../../Helpers/XR.ts";
import { OrbitControls as ThreeOrbitControls } from "../../Deps/ThreeJSM.ts";
import { Camera } from "../Components/Camera.ts";
import { ThreeJSTransform, ThreeJSTransformData } from "../Components/ThreeJSTransform.ts";
import { qnote } from "../../Helpers/Note.ts";

export class ThreeJSScene implements IScene {
  _objects: IThreeJSObject3D[] = [];
  _scene: Three.Scene;
  _renderer: Three.WebGLRenderer;
  _controls: ThreeOrbitControls | undefined;

  _showDebugAxes = false;

  constructor() {
    this._scene = new Three.Scene();

    this._renderer = new Three.WebGLRenderer({ antialias: true });
    this._renderer.setSize(window.innerWidth, window.innerHeight);
    this._renderer.setClearColor(0x000000);

    this.start();
    // this.render();
  }

  start = async () => {
    this._renderer.xr.enabled = await XR.isEnabled();

    const xrButton = await XRButton.createButton(this._renderer)

    if (xrButton != null) {
      document.getElementById("scene")!.appendChild(
        xrButton
      );
    }

    document.body.appendChild(this._renderer.domElement);
    console.log("xr is enabled ?", this._renderer.xr.enabled);
  };

  getDebugAxes = () => new Three.AxesHelper(10);

  add(object: IObject3D | IThreeJSObject3D) {
    const threeObject = "getThreeObject" in object
      ? object.getThreeObject()
      : null;
    if (!threeObject) {
      return;
    }

    if (object instanceof ThreeJSTransform && object.data!.group) {
      this._scene.add(threeObject);
      const parent = (object.entity?.parent?.transform as unknown as ThreeJSTransform)
      if (parent) {
        qnote('parenting group to ', parent)
        // parent.getThreeObject()?.attach(threeObject);
        threeObject.parent = parent.getThreeObject()
      }
    }
    else {
      this._scene.add(threeObject);

      const transform = (object.transform as unknown as ThreeJSTransform)
      if (transform.data!.group) {
        // transform.getThreeObject()!.attach(threeObject);
        threeObject.parent = transform.getThreeObject()!;
      }
    }

    this._objects.push(object as IThreeJSObject3D);
    if (this._showDebugAxes) {
      threeObject.add(this.getDebugAxes());
    }
  }

  showDebugAxes = (show: boolean) => {
    this._showDebugAxes = show;
    this._objects.forEach((object) => {
      if (show) {
        object.getThreeObject()!.add(this.getDebugAxes());
      }
    });
  };

  remove(object: IObject3D | IThreeJSObject3D) {
    const threeObject = "getThreeObject" in object
      ? object.getThreeObject()
      : null;
    if (!threeObject) {
      return;
    }
    this._scene.remove(threeObject);
    this._objects.remove(object as IThreeJSObject3D);
  }

  render = () => {
    const cam = Camera.main?._threeCamera;
    if (!cam) {
      return;
    }
    this._renderer.render(this._scene, cam);
  };
}
