import { alloc } from "../Helpers/Alloc.ts";
import {
	ComponentBlueprintInstance,
	EntityBlueprintInstance,
	IComponent,
	IEntity,
	IWorld,
	StringifiedComponentBlueprint,
	StringifiedEntityBlueprint,
} from './API.ts';
import { meta } from './Meta.ts';

export const serializeEntity = (
	entity: IEntity,
): StringifiedEntityBlueprint => {
	return alloc({
		id: entity.id,
		entity: entity.constructor.name,
		parentId: entity.parent ? entity.parent.id : null,
		components: entity.describe().components?.map(
			serializeComponentBlueprint,
		) || undefined,
	});
};

export const serializeComponent = (
	component: IComponent<unknown>,
): StringifiedComponentBlueprint<unknown> => {
	return alloc({
		component: component.constructor.name,
		data: component.describe(),
	});
};

export const serializeComponentBlueprint = <TData>(
	blueprint: ComponentBlueprintInstance<TData>,
): StringifiedComponentBlueprint<TData> => (alloc({
	component: blueprint.component.constructor.name,
	data: blueprint.data,
}));

export const deserializeEntityBlueprint = (
	blueprint: StringifiedEntityBlueprint,
	world: IWorld,
): EntityBlueprintInstance => {
	// ISSUE: allows anything to be spawned, no error handling
	const constructor = meta.getClass(blueprint.entity)!;
	const pool = meta.getPool(constructor)!;
	return alloc({
		entity: pool.take() as IEntity,
		id: blueprint.id,
		parent: blueprint.parentId ? world.get(blueprint.parentId) : null,
		privacy: blueprint.privacy,
		components: blueprint.components?.map(
			deserializeComponentBlueprint,
		) || undefined,
	});
};

export const deserializeComponentBlueprint = <TData>(
	blueprint: StringifiedComponentBlueprint<TData>,
): ComponentBlueprintInstance<TData> => {
	// ISSUE: allows anything to be spawned, no error handling
	const constructor = meta.getClass(blueprint.component)!;
	const pool = meta.getPool(constructor)!;
	return alloc({
		component: pool.take() as IComponent<TData>,
		privacy: blueprint.privacy,
		data: blueprint.data,
	});
};

// export const serializeComponent = <TData>(
//   component: IComponent<TData>,
// ): StringifiedComponentBlueprint<TData> => {
//   return {
//     component: component.constructor.name,
//     data: component.describe()!,
//   };
// };
