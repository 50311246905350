import { Component } from '../../Ontology/Entity/Component.ts';
import { IObject3D } from '../API.ts';
import { Transform } from './Transform.ts';
import { OrbitControls as ThreeOrbitControls } from '../../Deps/ThreeJSM.ts';
import { Camera } from './Camera.ts';
import { waitUntilX } from '../../Helpers/Wait.ts';
import { Note, qnote } from '../../Helpers/Note.ts';
import { Three } from '../../Deps/Three.ts';
import { Empty } from '../../Helpers/Types.ts';
import { ThreeJSTransform } from "./ThreeJSTransform.ts";

export class OrbitControls extends Component<Empty> implements IObject3D {
	static Instance: OrbitControls | undefined;
	_controls: ThreeOrbitControls | undefined;

	get transform() {
		return this.entity!.getComponent(ThreeJSTransform)! as unknown as any;
	}

	create = () => {
		if (OrbitControls.Instance !== undefined) {
			Note.warn('_', 'OrbitControls already exists');
			return;
		}
		OrbitControls.Instance = this;
		this.init();
	};

	update = () => {
		if (this._controls == null) {
			return;
		}
		this._controls.update();
	};

	destroy = () => {
		if (this == OrbitControls.Instance) {
			OrbitControls.Instance = undefined;
		}
	};

	init = () => {
		waitUntilX(() => Camera.main?._threeCamera != null, () => {
			qnote(
				'OrbitControls',
				'init',
				Camera.main?._threeCamera,
				document.getElementById('scene')!,
			);
			this._controls = new ThreeOrbitControls(
				Camera.main!._threeCamera!,
				document.getElementById('scene')!,
			);
			this._controls!.target = new Three.Vector3(0, 0.5, 0) as any;
		});
	};
}
