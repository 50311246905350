import { Three } from '../../Deps/Three.ts';
import { Axis, Input, Key } from '../../Helpers/Input.ts';
import { isNonZero } from '../../Helpers/Math.ts';
import { qnote } from '../../Helpers/Note.ts';
import { Time } from '../../Helpers/Time.ts';
import { Component } from '../../Ontology/Entity/Component.ts';
import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from '../../Helpers/Quaternion.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';
import { XR } from '../../Helpers/XR.ts';
import { ThreeJSTransform } from "./ThreeJSTransform.ts";
import { Entity } from "../../Ontology/Entity.ts";

type PlayerControlsData = {
	speed: number;
};

const MOVE_DEADZONE = 0.01;

export class PlayerControls extends Component<PlayerControlsData> {
	private x = 0;
	private y = 0;
	
	private v = new Vector3()
	private v2 = new Vector3()

	private q = new Quaternion();
	private q2 = new Quaternion();

	private justTurned = false;

	private lHand: Entity | null = null;
	private rHand: Entity | null = null;

	create = () => {
		// add arrow Helpers
		if (!this.isOwner) {
			return;
		}
	};


	private __dummy1 = new Vector3();
	private __dummy2 = new Vector3();
	update = () => {
		if (!this.isOwner) {
			return;
		}

		const transform = this.entity!.transform!

		const playerHead = this.entity?.getChildByName('player-head')
			?.getComponent(ThreeJSComponent);

		const forward = playerHead!.entity!.transform!.forward(this.__dummy1);
		forward.y = 0;
		const right = playerHead!.entity!.transform!.right(this.__dummy2);
		right.y = 0

		//translate
		const moveX = Input.getAxis(Axis.moveX);
		const moveZ = Input.getAxis(Axis.moveZ);
		const speed = this.data!.speed * Time.deltaTime *
			(Input.getKey(Key.sprint) ? 1.67 : 1);

		// if (XR.isActive()) {
		// 	const zeroedPosition = new Vector3(playerHead!.transform.position)
		// 	zeroedPosition.y = 1
			
		// 	this.entity!.transform!.localPosition = zeroedPosition
		// }
		// else {
			if (Math.abs(moveZ) > MOVE_DEADZONE) {
				this.v = this.v.set(transform.data!.position).add(
					new Vector3(forward).multiply(-moveZ * speed))
				transform.data!.position.x = this.v.x;
				transform.data!.position.y = 0;
				transform.data!.position.z = this.v.z;
				transform.mutateImmediate(null)
				// this.entity?.transform?.mutate((data) => {
				// 	const vec = new Vector3(data.position)!.add(
				// 		new Vector3(forward).multiply(-moveZ * speed),
				// 	);
				// 	data.position.x = vec.x;
				// 	data.position.y = 0;
				// 	data.position.z = vec.z;
				// 	return data;
				// });
			}
			if (Math.abs(moveX) > MOVE_DEADZONE) {
				this.v2 = this.v2.set(transform.data!.position).add(
					new Vector3(right).multiply(moveX * speed))
				transform.data!.position.x = this.v2.x;
				transform.data!.position.y = 0;
				transform.data!.position.z = this.v2.z;
				transform.mutateImmediate(null)
				// this.entity?.transform?.mutate((data) => {
				// 	const vec = new Vector3(data.position)!.add(
				// 		new Vector3(right).multiply(moveX * speed),
				// 	);
				// 	data.position.x = vec.x;
				// 	data.position.y = 0;
				// 	data.position.z = vec.z;
				// 	return data;
				// });
			}
		// }

		//rotate
		if (XR.isActive()) {
			const turnAmount = Math.PI / 4;
			const lookX = -Input.getAxis(Axis.lookX);
			const isTurning = isNonZero(lookX, MOVE_DEADZONE)
			if (isTurning && !this.justTurned) {
				transform.rotation = this.q.set(transform.data!.rotation).rotateY(Math.sign(lookX) * turnAmount)
				transform.mutateImmediate(null);

				// this.entity?.transform?.mutate((data) => {
				// 	data.rotation = new Quaternion(data.rotation).rotateY(
				// 		Math.sign(lookX) * turnAmount,
				// 	);
				// 	return data;
				// });
				this.justTurned = true;
			} else if (this.justTurned && !isTurning) {
				this.justTurned = false;
			}
		}
		else {
			const lookX = Input.getAxis(Axis.lookX);
			const lookY = Input.getAxis(Axis.lookY);
	
			this.x = this.x + lookX * Time.deltaTime;
	
			const maxAngle = Math.PI / 2 - Math.PI / 8;
			this.y = Math.min(
				Math.max(this.y + lookY * Time.deltaTime, -maxAngle),
				maxAngle,
			);
	
			if (isNonZero(lookX)) {
				transform.data!.rotation = this.q.fromEuler_ALLOC(0, -this.x, 0);
				transform.mutateImmediate(null);
				// this.entity!.transform!.mutate_ALLOC((data) => {
				// 	data.rotation = this.q.fromEuler_ALLOC(0, -this.x, 0);
				// 	return data;
				// });
			}
			if (isNonZero(lookY)) {
				const headTransform = playerHead!.entity!.transform!;
				headTransform.data!.rotation = this.q2.fromEuler_ALLOC(-this.y, 0, 0);
				headTransform.mutateImmediate(null);

				// this.entity!.getChildByName("player-head")!.transform!.mutate_ALLOC((data) => {
				// 	data.rotation = this.q2.fromEuler_ALLOC(-this.y, 0, 0);
				// 	return data;
				// });
			}
		}
	};

	_hackFlipAround = () => {
		this.x = Math.PI
	}

	setLHand = (lHand: Entity) => {
		this.lHand = lHand;
	}

	setRHand = (rHand: Entity) => {
		this.rHand = rHand;
	}

	getDefaultData = () => ({
		speed: 8,
	});
}
