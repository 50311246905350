import { IScene } from '../Animus/API.ts';
import { Note } from '../Helpers/Note.ts';
import { Observable } from '../Helpers/Observable.ts';
import {
	EntityBlueprintInstance,
	IEntity,
	IWorld,
	WorldEvents,
} from './API.ts';
import { GlobalEntity } from './Entity.ts';

export class World extends Observable<WorldEvents> implements IWorld {
	entities: IEntity[] = [];

	constructor() {
		super();
		GlobalEntity.subscribe('component-create', (...params) => {
			this.emit('component-create', ...params);
		});
		GlobalEntity.subscribe('component-mutate', (...params) => {
			this.emit('component-mutate', ...params);
		});
		GlobalEntity.subscribe('component-destroy', (...params) => {
			this.emit('component-destroy', ...params);
		});
		GlobalEntity.subscribe('set-parent', (...params) => {
			this.emit('entity-set-parent', ...params);
		});
		GlobalEntity.subscribe('destroy', (entity, propagator) => {
			this.destroy(entity, propagator);
		});
	}

	spawn = <T extends IEntity>(
		entity: T,
		scene: IScene,
		blueprint: EntityBlueprintInstance | null,
		propagator: unknown | null,
	): T => {
		// Note.trace("world", "adding entity to world", blueprint);
		if (this.entities.includes(entity)) {
			Note.error(
				'world',
				`(create) Entity ${entity.id} already exists in world`,
			);
			throw new Error();
		}
		this.entities.push(entity);
		entity.create(blueprint ?? null, scene, propagator);
		this.emit('entity-create', entity, propagator);
		if (entity.children) {
			this.entities = this.entities.concat(entity.children);
			for (const child of entity.children) {
				this.emit('entity-create', child, propagator);
				// child.subscribe("destroy", this.destroy);
			}
		}

		// entity.subscribe("destroy", this.destroy);

		return entity;
	};

	// DO NOT CALL THIS DIRECTLY
	private destroy = (entity: IEntity, propagator: unknown | null) => {
		if (propagator === this) {
			return;
		}
		if (!this.entities.includes(entity)) {
			Note.error(
				'world',
				`(destroy) Entity ${entity.id} does not exist in world`,
			);
			throw new Error();
		}
		Note.trace('world', 'removing entity from world', entity);
		// entity.unsubscribe("destroy", this.destroy);
		for (const child of entity.children) {
			this.emit('entity-destroy', child, propagator);
		}
		this.emit('entity-destroy', entity, propagator);
		this.entities = this.entities.filter((e) =>
			e !== entity && !entity.children.includes(e)
		);
		// entity.destroy(propagator);
	};
	// END

	get = (id: string): IEntity | null => {
		for (const entity of this.entities) {
			if (entity.id === id) {
				return entity;
			}
		}
		return null;
	};

	tick = () => {
		for (let i = 0; i < this.entities.length; i++) {
			const entity = this.entities[i];
			entity.update && entity.update();
		}
	};
}

//
