import { Entity } from "../../Ontology/Entity.ts";
import { GLTFMesh } from "../Components/GLTFMesh.ts";
import { ThreeJSTransform } from "../Components/ThreeJSTransform.ts";
import { Transform } from "../Components/Transform.ts";

export class Gun extends Entity {
  getDefaultBlueprint = () => {
    return {
      name: "gun",
      components: [
        {
          component: new ThreeJSTransform(),
          data: {
            position: {
              x: 1,
              y: 2,
              z: 3,
            },
            rotation: {
              w: 0,
              x: 0,
              y: 0,
              z: 0,
            },
          },
        },
        {
            component: new GLTFMesh(),
            data: {
                url: "./assets/glock.glb",
            },
        }
      ],
    };
  };
}
