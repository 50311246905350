import { Three } from "../../Deps/Three.ts";
import { IThreeJSObject3D } from "../API.ts";
import { ThreeJSComponent } from "./ThreeJSComponent.ts";

export class CubeRenderer1k extends ThreeJSComponent<{}> implements IThreeJSObject3D {
  _threeObject: Three.InstancedMesh | null = null;
  _positions: Three.Object3D[] = [];

  createThreeObject = (): Promise<Three.Object3D> => {
    const geometry = new Three.BoxGeometry(0.1, 0.1, 0.1);
    const material = new Three.MeshLambertMaterial({
      color: 0xffffff * Math.random() * 2,
    });
    const mesh = new Three.InstancedMesh(geometry, material, 1000);
    for (let i = 0; i < 1000; i++) {
      const dummy = new Three.Object3D();
      dummy.position.set(
        Math.random() * 10 - 5,
        Math.random() * 10 - 5,
        Math.random() * 10 - 5,
      );
      dummy.updateMatrix();
      mesh.setMatrixAt(i, dummy.matrix);
      this._positions.push(dummy);
    }
    return Promise.resolve(mesh as unknown as Three.Object3D);
  };

  myScale = Math.random();

  update = () => {
    for (let i = 0; i < 1000; i++) {
      const dummy = this._positions[i];
      dummy.rotateX(0.1 * Math.random());
      dummy.rotateY(0.1 * Math.random());
      dummy.updateMatrix();
      this._threeObject!.setMatrixAt(i, dummy.matrix);
    }
    this._threeObject!.instanceMatrix.needsUpdate = true;
  };
}
