import { Player, PlayerXRComponent } from './Core/Animus/Entities/Player.ts';
import { GaiaNode } from './Core/Gaia/GaiaNode.ts';
import { Constructor } from './Core/Helpers/Types.ts';
import { meta } from './Core/Ontology/Meta.ts';
import { Entity, UpdatingEntity } from './Core/Ontology/Entity.ts';
import { Component } from './Core/Ontology/Entity/Component.ts';
import { Camera } from './Core/Animus/Components/Camera.ts';
import { Light } from './Core/Animus/Components/Light.ts';
import { CubeRenderer } from './Core/Animus/Components/CubeRenderer.ts';
import { CubeRenderer1k } from './Core/Animus/Components/CubeRenderer1k.ts';
import { AudioSource } from './Core/Animus/Components/AudioSource.ts';
import { AudioListener } from './Core/Animus/Components/AudioListener.ts';
import { XRFollower } from './Core/Animus/Components/XRFollower.ts';
import { Fox } from './Core/Animus/Components/Fox.ts';
import { GLTFMesh } from './Core/Animus/Components/GLTFMesh.ts';
import { SimpleEntity } from './Core/Animus/Entities/Simple.ts';
import { OrbitControls } from './Core/Animus/Components/OrbitControls.ts';
import { Spinner } from './Core/Animus/Components/Spinner.ts';
import { NestedCube } from './Core/Animus/Entities/NestedCube.ts';
import { Input } from './Core/Helpers/Input.ts';
import { PlayerControls } from "./Core/Animus/Components/PlayerControls.ts";
import { HypercubeRenderer } from "./Core/Animus/Components/HypercubeRenderer.ts";
import { Empty } from "./Core/Animus/Components/Empty.ts";
import { ThreeJSTransform } from "./Core/Animus/Components/ThreeJSTransform.ts";
import { Follower } from "./Core/Animus/Components/Follower.ts";
import { Gun } from "./Core/Animus/Entities/Gun.ts";
import { Stats3D } from "./Core/Animus/Components/Stats3D.ts";
import { Stats2D } from "./Core/Animus/Components/Stats2D.ts";
import { RelativityGrid } from "./Core/Animus/Components/RelativityGrid.ts";
import { PlayerHand } from "./Core/Animus/Components/PlayerHand.ts";

const init = () => {

	// 👋 NOTE: ADD EVERY CLASS HERE (!!!)
	// This tracks which classes can be instantiated by various commands & across the network.

	// TODO: Automate this
	const classes: Constructor<unknown>[] = [
		// Entities
		Entity,
		UpdatingEntity,
		Player,
		SimpleEntity,
		NestedCube,
		Gun,
		PlayerHand,

		// Component
		Empty,
		Component,
		ThreeJSTransform,
		RelativityGrid,
		GaiaNode,
		Light,
		CubeRenderer,
		CubeRenderer1k,
		HypercubeRenderer,
		Camera,
		AudioSource,
		AudioListener,
		Spinner,
		Follower,
		XRFollower,
		Fox,
		GLTFMesh,
		PlayerXRComponent,
		OrbitControls,
		PlayerControls,
		Stats2D,
		Stats3D,
	];

	for (const cls of classes) {
		meta.registerClass(cls);
	}

	Input._initialize();

	// @ts-ignore meta
	window.meta = meta;
};

export { init };
