export function randomBetween(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

function gaussianRand() {
  let rand = 0;
  for (let i = 0; i < 6; i += 1) rand += Math.random();
  return rand / 6;
}

export function gaussian(min, max) {
  return Math.floor(min + gaussianRand() * (max - min + 1));
}
