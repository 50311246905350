import { IEntity, IWorld } from "../Ontology/API.ts";
import * as vis from "https://cdn.skypack.dev/vis-network";
import debounce from "https://cdn.skypack.dev/debounce?dts";
import tippy, {
  followCursor,
  Instance as TippyInstance,
} from "https://cdn.skypack.dev/tippy.js?dts";
import { GaiaNode } from "./GaiaNode.ts";

export class GaiaScene {
  world: IWorld;
  entities: IEntity[] = [];
  network: vis.Network | null = null;
  tooltip: TippyInstance | null = null;
  hidden = false;

  constructor(world: IWorld) {
    this.world = world;
    this.world.entities.forEach(this.onSpawn);
    this.world.subscribe("entity-create", this.onSpawn);
    this.world.subscribe("entity-destroy", this.onDestroy);
  }

  onSpawn = (entity: IEntity) => {
    entity.addComponent(GaiaNode, { privacy: "private" }, this);
    this.entities.push(entity);
    this.updateNetwork();
  };
  onDestroy = (entity: IEntity) => {
    this.entities = this.entities.filter((e) => e !== entity);
    this.updateNetwork();
  };

  updateNetwork = debounce(() => {
    if (this.hidden) {
      return;
    }
    // { id: 0, label: "0", group: 0 },
    const nodes = [
      ...this.entities.flatMap((x) => [
        {
          id: x.id,
          label: x.name,
          group: x.privacy === "public" ? 1 : 3,
        },
        ...x.components.flatMap((c) =>
          c.name === "GaiaNode" ? [] : [{
            id: `${x.id}-${c.name}`,
            label: c.name,
            group: (x.privacy === "public" && c.privacy === "public") ? 1 : 3,
            size: 16,
            font: {
              size: 16,
            },
          }]
        ),
      ]),
    ];
    // { from: 1, to: 0 },
    const edges = [
      ...this.entities.flatMap((x) =>
        x.components.flatMap((c) => [{
          from: `${x.id}-${c.constructor.name}`,
          to: x.id,
        }])
      ),
    ];

    // create a network
    const data = {
      nodes: nodes,
      edges: edges,
    };

    if (this.network) {
      this.network.setData(data);
    } else {
      const container = document.getElementById("scene");
      this.tooltip = tippy(container!, {
        plugins: [followCursor],
        theme: "light",
        placement: "top",
        trigger: "manual",
      });
      const options = {
        nodes: {
          shape: "dot",
          size: 30,
          font: {
            size: 32,
            color: "#ccc",
          },
          borderWidth: 2,
          shadow: true,
        },
        edges: {
          width: 2,
          shadow: true,
        },
        layout: {
          improvedLayout: false,
        },
        physics: {
          stabilization: false,
        },
        interaction: {
          hover: true,
        },
      };
      this.network = new vis.Network(container!, data, options);

      // @ts-ignore yes it does
      this.network.on("blurNode", (params) => {
        this.tooltip!.hide();
      });

      // @ts-ignore yes it does
      this.network.on("hoverNode", (params) => {
        // const {x, y} = params.pointer.DOM
        const nodeId = params.node;
        const nodePos = this.network!.getPosition(nodeId);
        const { x, y } = this.network!.canvasToDOM({
          x: nodePos.x,
          y: nodePos.y,
        });

        // split node id
        const [entityId, componentId] = nodeId.split("-");
        const entity = this.entities.find((e) => e.id === entityId);
        if (!entity) {
          return;
        }
        if (componentId === undefined) {
          // is entity
          this.updateTooltip(x, y, {
            privacy: entity.privacy,
            name: entity.name,
            id: entity.id,
          });
        } else {
          // is component
          const component = entity.getComponent(componentId);
          if (!component) {
            return;
          }

          this.updateTooltip(x, y, component.describe());
        }
      });
    }
  }, 500);

  updateTooltip = debounce(
    (x, y, data) => {
      this.tooltip!.enable();
      this.tooltip!.show();
      this.tooltip!.setProps({
        content: `${JSON.stringify(data)}`,
        // @ts-ignore
        getReferenceClientRect: () => ({
          left: x,
          top: y,
          width: 0,
          height: 0,
          x,
          y,
          bottom: y,
          right: x,
        }),
      });
    },
    100,
    true,
  );

  show = () => {
    this.hidden = false;
    this.updateNetwork();
  };

  hide = () => {
    this.hidden = true;
    this.network?.destroy();
  };
}
