import {XRSession} from "../Deps/WebXR.ts"

export {}

declare global {
  interface Array<T> {
    remove(o: T): Array<T>;
  }
  
  interface Navigator {
    xr: {
      isSessionSupported(sessionType: string): boolean;
      requestSession(sessionType: string): Promise<XRSession>;
    }
  }
}


Array.prototype.remove = function arrayShiftRemove(item) {
  let index = this.indexOf(item);

  const stop = this.length - 1;
  while (index < stop) {
    this[index] = this[++index];
  }

  this.pop();
  return this;
};
