import { IThreeJSObject3D } from "../API.ts";
import { Three } from "../../Deps/Three.ts";
import { ThreeJSComponent } from "./ThreeJSComponent.ts";

export type LightData = {
  type?: "ambient" | "directional" | "point" | "spot";
  color?: Three.ColorRepresentation;
  intensity?: number;
};

export class Light extends ThreeJSComponent<LightData> implements IThreeJSObject3D {
  createThreeObject = (): Promise<Three.Object3D> => {
    const { color, type, intensity } = this.data!;
    let light;
    if (type == "point") {
      light = new Three.PointLight(color, intensity ?? 1, 100);
    } else if (type == "spot") {
      light = new Three.SpotLight(color, intensity ?? 1, 100);
    } else if (type == "ambient") {
      light = new Three.AmbientLight(color, intensity ?? 1);
    } else {
      light = new Three.DirectionalLight(color, intensity ?? 1);
    }
    const pos = this.transform.data!.position;
    light.position.set(pos.x, pos.y, pos.x);
    return Promise.resolve(light);
  };

  getDefaultData = (): LightData => {
    return {
      type: "point",
      color: 0xffffff,
      intensity: 0.9,
    };
  };
}
