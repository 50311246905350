import { Debug } from './Debug.ts';

type IndividualAllocData = {
    name: string;
    source: string;
    line: number;
    quantity: number;
}

type AllocData = {
    num: number,
    map: Map<string, number>
    sources: {
        [key: string]: IndividualAllocData;
    }
    dump: any
}

const allocData: AllocData = {
	num: 0,
	map: new Map<string, number>(),
	sources: {},
	dump: () => {
		console.log(allocData.num);
		console.log(allocData.map);
		const sortedSources = Object.keys(allocData.sources).sort((a, b) =>
			allocData.sources[b].quantity - allocData.sources[a].quantity
		).filter(x => allocData.sources[x].quantity > 1);
        for (const source of sortedSources) {
            console.log(`${allocData.sources[source].quantity} allocations of ${allocData.sources[source].name} at\n${source}`);
        }
	},
};

(window as any).alloc = allocData;

export const alloc = <T>(obj: T): T => {
	if (Debug.enabled) {
		// @ts-ignore this should be ok
		const objName = obj && obj.constructor && obj.constructor.name;

        if (Debug.superEnabled) {
            const err = new Error();
            const callStack = err.stack!.split('\n').slice(2).join('\n')
            const entry = allocData.sources[callStack];
            if (entry != null) {
                entry.quantity = entry.quantity + 1;
            } else {
                allocData.sources[callStack] = {
                    name: objName,
                    source: callStack,
                    line: 0,
                    quantity: 1,
                }
            }
        }

        // Record to the map
		const entry = allocData.map.get(objName);
		if (entry != null) {
			allocData.map.set(objName, entry + 1);
		} else {
			allocData.map.set(objName, 0);
		}
	}

	// TODO: do something better with allocations
	allocData && allocData.num++;
	return obj;
};
