import { alloc } from '../../Helpers/Alloc.ts';
import { Observable } from '../../Helpers/Observable.ts';
import { uuidShort } from '../../Helpers/Uuid.ts';
import type {
	ComponentBlueprintData,
	ComponentEvents,
	IComponent,
	IEntity,
} from '../API.ts';
import { meta } from '../Meta.ts';

export class Component<TData> extends Observable<ComponentEvents>
	implements IComponent<TData> {
	name: string | undefined;
	privacy: 'public' | 'private' = 'public';
	state: 'alive' | 'dead' = 'dead';

	data: TData | undefined;
	id: string | undefined;
	entity: IEntity | undefined;
	updating = false;
	isOwner = true;

	super: Component<TData>;

	constructor() {
		super();
		this.super = {
			...this,
		};
	}

	// Override me
	create = () => {};
	mutateOverride = (data: TData): TData => data;
	destroy = (propagator: unknown = undefined) => {
		this._internal_Destroy(propagator);
	};

	_internal_Create = (
		entity: IEntity,
		blueprint: ComponentBlueprintData<any> | null,
		propagator: unknown | undefined,
	): IComponent<TData> => {
		this.id = uuidShort();
		this.entity = entity;
		this.name = this.constructor.name;
		this.privacy = blueprint?.privacy ?? 'public';
		// ISSUE: Creates garbage, may want to update
		this.data = {
			...this.getDefaultData(),
			...(blueprint?.data || {}),
		};
		this.state = 'alive';
		this.create();
		this.emit('create', this, propagator);
		return this;
	};

	mutate_ALLOC = (
		fn: (data: TData) => TData,
		propagator: unknown | undefined = undefined,
	): void => {
		this.data = this.mutateOverride(alloc(fn(this.data!)));
		this.emit('mutate', this, this.data, propagator);
	};

	mutateImmediate = (propagator: unknown | undefined = undefined): void => {
		this.emit('mutate', this, this.data, propagator);
	};

	_internal_Destroy = (propagator: unknown): IComponent<TData> => {
		// this.destroy();
		this.emit('destroy', this, propagator);
		this.id = '';
		this.entity = undefined;
		this.data = undefined;
		this.state = 'dead';
		this.unsubscribeAll();
		meta.getPool(this.constructor as any)!.release(this);
		return this;
	};

	update;
	tags = undefined;

	describe = () => {
		return this.data!;
	};

	getDefaultData = (): TData => {
		return alloc({}) as TData;
	};

	send = (message: any) => {
		this.emit('send-message', this, message);
	};

	receive = (message: any) => {
		this.emit('receive-message', this, message);
	};
}
