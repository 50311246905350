import { Three } from '../../Deps/Three.ts';
import { Component } from '../../Ontology/Entity/Component.ts';
import { ThreeJSComponent } from './ThreeJSComponent.ts';

// Renders a grid of relativity spacetime lines using three.js
export class RelativityGrid extends ThreeJSComponent<void> {
	constructor() {
		super();
	}

	createThreeObject = async () => {
		const size = 10, divisions = 10;

		const color1 = new Three.Color(0x444444);
		const color2 = new Three.Color(0x888888);

		const center = divisions / 2;
		const step = size / divisions;
		const halfSize = size / 2;

		const vertices: any[] = [], colors: any[] = [];

		for (let i = 0, j = 0, k = -halfSize; i <= divisions; i++, k += step) {
			vertices.push(-halfSize, 0, k, halfSize, 0, k);
			vertices.push(k, 0, -halfSize, k, 0, halfSize);

			const color = i === center ? color1 : color2;

			color.toArray(colors, j);
			j += 3;
			color.toArray(colors, j);
			j += 3;
			color.toArray(colors, j);
			j += 3;
			color.toArray(colors, j);
			j += 3;
		}

		const geometry = new Three.BufferGeometry();
		geometry.setAttribute(
			'position',
			new Three.Float32BufferAttribute(vertices, 3),
		);
		geometry.setAttribute(
			'color',
			new Three.Float32BufferAttribute(colors, 3),
		);

		const material = new Three.LineBasicMaterial({
			vertexColors: true,
			toneMapped: false,
		});

		const lineSegments = new Three.LineSegments(geometry, material);

		return await Promise.resolve(lineSegments);
	};
}
