import { Vector3, Vector3Like } from '../../Helpers/Vector3.ts';
import { Quaternion, QuaternionLike } from '../../Helpers/Quaternion.ts';
import { ThreeJSComponent, ThreeJSObjectData } from './ThreeJSComponent.ts';
import { Three } from '../../Deps/Three.ts';
import { ITransform } from '../../Ontology/API.ts';
import { ThreeJSHelper } from '../../Helpers/ThreeJSHelper.ts';
import { alloc } from '../../Helpers/Alloc.ts';
import { qnote } from '../../Helpers/Note.ts';
import { gaussian } from '../../Helpers/Random.ts';

export type TransformData = {
	position: Vector3Like;
	rotation: QuaternionLike;
	scale: Vector3Like;
};

export type ThreeJSTransformData = ThreeJSObjectData & TransformData & {
	group: boolean;
};

const RANDOMIZE_DEFAULT = false;

export class ThreeJSTransform extends ThreeJSComponent<ThreeJSTransformData>
	implements ITransform<ThreeJSTransformData> {
	threePos = new Three.Vector3();
	threeRot = new Three.Quaternion();
	threeScale = new Three.Vector3();

	createThreeObject = (): Promise<Three.Object3D | null> => {
		if (this.data?.group) {
			const group = new Three.Group();
			this.updatePosRotScale(group, this.data!);
			return Promise.resolve(group);
		} else {
			return Promise.resolve(null);
		}
	};

	get position(): Vector3Like {
		return this.data!.position;
	}

	set position(vec: Vector3Like) {
		this.data!.position = vec;
		this.mutateImmediate();
	}

	get forward_ALLOC(): Vector3Like {
		return this.transformDirection_ALLOC(Vector3.forward);
	}
	get up_ALLOC(): Vector3Like {
		return this.transformDirection_ALLOC(Vector3.up);
	}
	get right_ALLOC(): Vector3Like {
		return this.transformDirection_ALLOC(Vector3.right);
	}

	forward(target: Vector3Like): Vector3Like {
		return this.transformDirection(Vector3.staticForward, target);
	}

	up(target: Vector3Like): Vector3Like {
		return this.transformDirection(Vector3.staticUp, target);
	}

	right(target: Vector3Like): Vector3Like {
		return this.transformDirection(Vector3.staticRight, target);
	}

	get rotation(): QuaternionLike {
		return this._threeObject!.getWorldQuaternion(this.threeRot);
	}

	set rotation(q: QuaternionLike) {
		this.data!.rotation = q;
		this.mutateImmediate();
	}

	get scale(): Vector3Like {
		return Vector3.one;
	}

	get localPosition(): Vector3Like {
		return this.data!.position;
	}

	set localPosition(vec: Vector3Like) {
		this.data!.position = vec;
		this.mutateImmediate();
	}

	get localRotation(): QuaternionLike {
		return this.data!.rotation;
	}

	set localRotation(q: QuaternionLike) {
		this.data!.rotation = q;
		this.mutateImmediate();
	}

	get localScale(): Vector3Like {
		return Vector3.one;
	}

	transformPoint = (point: Vector3Like): Vector3Like => {
		const worldOffset = this._threeObject!.getWorldPosition(this.threePos);
		const worldPos = Vector3.subtract(point, worldOffset);
		return worldPos;
	};

	inverseTransformPoint = (point: Vector3Like): Vector3Like => {
		const worldOffset = this._threeObject!.getWorldPosition(this.threePos);
		const localPos = Vector3.add(worldOffset, point);
		return localPos;
	};

	__dummy1 = new Three.Vector3();
	transformDirection = (
		dir: Vector3Like,
		target: Vector3Like,
	): Vector3Like => {
		const localDir = ThreeJSHelper.toVec(dir, this.__dummy1)
			.transformDirection(
				this._threeObject!.matrixWorld,
			);
		Vector3.set(target, localDir);
		return target;
	};

	transformDirection_ALLOC = (dir: Vector3Like): Vector3Like => {
		const localDir = ThreeJSHelper.toVec(dir).transformDirection(
			this._threeObject!.matrixWorld,
		);
		return localDir;
	};

	mutateOverride = (data: ThreeJSTransformData): ThreeJSTransformData => {
		// qnote(data)
		if (data.group && !this.data!.group) {
			// Now a group!
			this._threeObject = new Three.Group();
		}
		if (!data.group && this.data!.group) {
			// No longer a group, currently unhandled
			throw new Error('Unhandled disabling of group on ThreeJSTransform');
		}
		this.updatePosRotScale(this._threeObject!, data);
		return data;
	};

	update = () => {
		this.updatePosRotScale(this._threeObject!, this.data!);
	};

	updatePosRotScale = (
		threeObject: Three.Object3D,
		data: ThreeJSTransformData,
	): void => {
		threeObject!.position.copy(
			ThreeJSHelper.toVec(data.position, this.threePos),
		);
		threeObject!.rotation.setFromQuaternion(
			ThreeJSHelper.toQuat(data.rotation, this.threeRot),
		);
		threeObject!.scale.copy(
			ThreeJSHelper.toVec(data.scale, this.threeScale),
		);
	};

	getDefaultData = (): ThreeJSTransformData => {
		if (!RANDOMIZE_DEFAULT) {
			return alloc({
				position: Vector3.zero,
				rotation: Quaternion.identity,
				scale: Vector3.one,
				group: true,
			});
		} else {
			return alloc({
				...ThreeJSTransform.getRandomTransformData(),
				group: true,
			});
		}
	};

	static getRandomTransformData(): TransformData {
		const scale = Math.max(0.1, gaussian(0.1, 1.5));
		return alloc({
			position: {
				x: Math.random() * 10 - 5,
				y: Math.random() * 10 - 5,
				z: Math.random() * 10 - 5,
			},
			rotation: {
				w: Math.random() * Math.PI * 2,
				x: Math.random() * Math.PI * 2,
				y: Math.random() * Math.PI * 2,
				z: Math.random() * Math.PI * 2,
			},
			scale: {
				x: scale,
				y: scale,
				z: scale,
			},
		});
	}
}
