import { DDSLoader, MTLLoader, OBJLoader } from "../../Deps/ThreeJSM.ts";
import { Three } from "../../Deps/Three.ts";
import { Empty } from "../../Helpers/Types.ts";
import { Component } from "../../Ontology/Entity/Component.ts";
import { IThreeJSObject3D } from "../API.ts";
import { ThreeJSComponent } from "./ThreeJSComponent.ts";
import { Transform } from "./Transform.ts";

const path = "assets/";
const objName = "fox2.obj?text";
const mtlName = "fox2.mtl?text";

export class Fox extends ThreeJSComponent<Empty> implements IThreeJSObject3D {
  createThreeObject = async (): Promise<Three.Object3D> => {
    const manager = new Three.LoadingManager();
    manager.addHandler(/\.dds$/i, new DDSLoader());
    const materials = await new MTLLoader(manager).setPath(path).loadAsync(
      mtlName,
    ) as any;
    materials.preload();

    const group = await new OBJLoader(manager).setPath(path).setMaterials(
      materials,
    ).loadAsync(objName) as Three.Group;

    group.scale.set(.15, 0.15, 0.15);

    return group;
  };
}
